import { Nft } from '@metaplex-foundation/js-next';
import { BN } from '@project-serum/anchor';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import {
  PublicKey,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  SYSVAR_SLOT_HASHES_PUBKEY,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import { CATAPULT_PROGRAM_ID } from '../constants';
import { Context } from '../context';
import {
  LaunchAccount,
  LaunchItem,
  PayloadItem,
  PayloadMemoAccount,
  WalletData,
} from '../types';
import {
  findAssociatedTokenAccountAddress,
  findAtaCustodiedTokenPayload,
  findCatapult,
  findCollectionNumbering,
  findItemNumber,
  findItemNumberAttribution,
  findLaunch,
  findMetadataAddress,
  findPayloadMemo,
} from '../utils';
import bs58 from 'bs58';

const createCatapultModule = (context: Context) => {
  return async (collection: PublicKey, wallet?: PublicKey) => {
    const collectionMetadata = await findMetadataAddress(collection);
    const collectionNumbering = await findCollectionNumbering(collection);
    const catapult = await findCatapult(collectionNumbering);

    const registerCollection = async () => {
      const createCollectionNumbering = await context.catapultProgram.methods
        .createCollectionNumbering()
        .accounts({
          payer: context.provider.wallet.publicKey,
          collectionMetadata,
          collectionMint: collection,
          collectionNumbering,
        })
        .instruction();
      const createCatapult = await context.catapultProgram.methods
        .createCatapult()
        .accounts({
          payer: context.provider.wallet.publicKey,
          collectionNumbering,
          catapult,
        })
        .instruction();
      const tx = new Transaction();
      tx.add(createCollectionNumbering);
      tx.add(createCatapult);
      const signature = await context.provider.send(tx);
      return { signature };
    };

    const registerItem = async (item: PublicKey) => {
      const nft = await context.metaplex.nfts().findByMint(item);
      const itemNumber = await findItemNumber(collectionNumbering, item);
      const collectionNumberingMetadata =
        await context.catapultProgram.account.collectionNumbering.fetch(
          collectionNumbering,
        );
      const numItems = collectionNumberingMetadata.numberedItems;
      const itemNumberAttribution = await findItemNumberAttribution(
        collectionNumbering,
        new BN(numItems),
      );
      const incrementNumbering = await context.catapultProgram.methods
        .addItemToCollectionNumbering()
        .accounts({
          payer: context.provider.wallet.publicKey,
          collectionNumbering,
          itemMetadata: nft.metadataAccount.publicKey,
          itemNumber,
          itemNumberAttribution,
        })
        .instruction();
      const tx = new Transaction();
      tx.add(incrementNumbering);
      const signature = await context.provider.send(tx);
      return { signature };
    };

    const launchNativePayload = async (amount: number, message?: string) => {
      const nextLaunch = await _nextLaunchKey();
      const payloadMemo = await findPayloadMemo(nextLaunch, 0);
      const createNextLaunch = await context.catapultProgram.methods
        .createNextLaunch()
        .accounts({
          payer: context.provider.wallet.publicKey,
          catapult,
          launch: nextLaunch,
        })
        .instruction();
      const loadNativePayload = await context.catapultProgram.methods
        .loadNativePayload({
          message: message ?? '',
          amount: new BN(amount),
        })
        .accounts({
          payer: context.provider.wallet.publicKey,
          payloadProvider: context.provider.wallet.publicKey,
          catapult,
          launch: nextLaunch,
          payloadMemo,
        })
        .instruction();
      const fire = await context.catapultProgram.methods
        .fire()
        .accounts({
          payer: context.provider.wallet.publicKey,
          numbering: collectionNumbering,
          catapult,
          launch: nextLaunch,
          slotHashes: SYSVAR_SLOT_HASHES_PUBKEY,
          instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        })
        .instruction();
      const tx = new Transaction();
      tx.add(createNextLaunch);
      tx.add(loadNativePayload);
      tx.add(fire);

      const signature = await context.provider.send(tx);
      return { signature, launch: nextLaunch };
    };

    const launchTokenPayload = async (
      splToken: PublicKey,
      amount: number,
      message?: string,
    ) => {
      const nextLaunch = await _nextLaunchKey();
      const payloadMemo = await findPayloadMemo(nextLaunch, 0);
      const inputTokenPayload = await getAssociatedTokenAddress(
        splToken,
        context.provider.wallet.publicKey,
      );
      const custodiedTokenPayload = await findAtaCustodiedTokenPayload(
        splToken,
        nextLaunch,
      );
      const createNextLaunch = await context.catapultProgram.methods
        .createNextLaunch()
        .accounts({
          payer: context.provider.wallet.publicKey,
          catapult,
          launch: nextLaunch,
        })
        .instruction();
      const loadTokenPayload = await context.catapultProgram.methods
        .loadTokenPayload({ message: message ?? '', amount: new BN(amount) })
        .accounts({
          payer: context.provider.wallet.publicKey,
          payloadProvider: context.provider.wallet.publicKey,
          inputTokenPayload: inputTokenPayload,
          payloadMint: splToken,
          custodiedTokenPayload,
          catapult,
          launch: nextLaunch,
          payloadMemo,
        })
        .instruction();
      const fire = await context.catapultProgram.methods
        .fire()
        .accounts({
          payer: context.provider.wallet.publicKey,
          numbering: collectionNumbering,
          catapult,
          launch: nextLaunch,
          slotHashes: SYSVAR_SLOT_HASHES_PUBKEY,
          instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        })
        .instruction();
      const tx = new Transaction();
      tx.add(createNextLaunch);
      tx.add(loadTokenPayload);
      tx.add(fire);
      const signature = await context.provider.send(tx);
      return { signature, launch: nextLaunch };
    };

    const isReadyForLanding = async (launch: PublicKey) => {
      const launchData = await context.catapultProgram.account.launch.fetch(
        launch,
      );
      const desiredSlot = launchData.landingSlot.toNumber();
      const currentSlot = await context.provider.connection.getSlot();
      return currentSlot > desiredSlot;
    };

    const land = async (launch: PublicKey) => {
      const isReady = await isReadyForLanding(launch);
      if (!isReady) {
        throw new Error('Slot requirement has not been met.');
      }

      const landLaunch = await context.catapultProgram.methods
        .land()
        .accounts({
          payer: context.provider.wallet.publicKey,
          launch,
          slotHashes: SYSVAR_SLOT_HASHES_PUBKEY,
          instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        })
        .instruction();
      const tx = new Transaction();
      tx.add(landLaunch);
      const signature = await context.provider.send(tx);
      return { signature };
    };

    const getLaunchFeed = async (): Promise<LaunchItem[]> => {
      const launchItems: LaunchItem[] = [];
      const launches = await _fetchAllLaunchKeys();
      for (const launch of launches) {
        const payloads = await _fetchAllPayloadMemosFromLaunch(launch);
        const launchMetadata =
          await context.catapultProgram.account.launch.fetch(launch);
        const formattedLaunch = await _formatLaunch(launchMetadata, payloads);
        launchItems.push(formattedLaunch);
      }
      return launchItems.sort((a, b) => {
        return b.estimatedTimestamp - a.estimatedTimestamp;
      });
    };

    const fetchWalletData = async (
      walletAddress?: PublicKey,
    ): Promise<WalletData> => {
      if (!walletAddress && !wallet) {
        throw new Error('No wallet provided.');
      }
      const walletToUse = walletAddress ? walletAddress : wallet;
      const registeredNfts = await _fetchAllRegisteredNftsByWalletOwner(
        walletToUse as PublicKey,
      );
      const walletData: WalletData = {
        registered: [],
        catapults: [],
      };
      // Get registered
      for (let i = 0; i < registeredNfts.length; i++) {
        const nft = registeredNfts[i];
        const launches = await _fetchAllLaunchesLandedOnItem(nft.mint);
        const lands: LaunchItem[] = [];
        for (let j = 0; j < launches.length; j++) {
          const launch = launches[j];
          const payloads = await _fetchAllPayloadMemosFromLaunch(launch);
          const launchData = await context.catapultProgram.account.launch.fetch(
            launch,
          );
          const formattedLaunch = await _formatLaunch(launchData, payloads);
          lands.push(formattedLaunch);
        }
        await nft.metadataTask.run();
        walletData.registered.push({
          data: nft,
          lands,
        });
      }

      // Get catapults
      const payloadsFromWallet = await _fetchAllPayloadMemosBySourceWallet(
        walletToUse,
      );
      const groupedPayloads = _groupPayloadsByLaunchIndex(payloadsFromWallet);
      const launchIndexes = Object.keys(groupedPayloads);
      for (const launchIndex of launchIndexes) {
        const bnLaunchIndex: BN = new BN(parseInt(launchIndex));
        const launch = await findLaunch(catapult, bnLaunchIndex);
        const payloads = groupedPayloads[launchIndex];
        const launchData = await context.catapultProgram.account.launch.fetch(
          launch,
        );
        const formattedLaunch = await _formatLaunch(launchData, payloads);
        walletData.catapults.push(formattedLaunch);
      }
      return walletData;
    };

    const getAllItemsForRegistration = async (walletAddress?: PublicKey) => {
      if (!walletAddress && !wallet) {
        throw new Error('No wallet provided.');
      }
      const walletToUse = walletAddress ? walletAddress : wallet;
      if (!walletToUse) {
        throw new Error('No wallet provided.');
      }
      const nfts = await context.metaplex.nfts().findAllByOwner(walletToUse);
      const nftsForCollection = nfts.filter(
        (nft) => nft.collection?.key.toBase58() === collection.toBase58(),
      );
      const allRegistered = await _filterNftsByRegistered(nftsForCollection);
      const allRegisteredPubkeys = allRegistered.map((nft) =>
        nft.mint.toBase58(),
      );
      const nonRegistered = nftsForCollection.filter(
        (nft) =>
          !allRegisteredPubkeys.some(
            (registeredNft) => registeredNft === nft.mint.toBase58(),
          ),
      );
      return nonRegistered;
    };

    const claim = async (
      launch: PublicKey,
      item: PublicKey,
      token?: PublicKey,
    ) => {
      const claimantItemTokenAccount = await findAssociatedTokenAccountAddress(
        context.options.wallet.publicKey,
        item,
      );
      const itemNumber = await findItemNumber(collectionNumbering, item);
      let claimIx: TransactionInstruction;
      if (token) {
        const custodiedTokenPayload = await findAtaCustodiedTokenPayload(
          token,
          launch,
        );
        const claimantTokenPayload = await findAssociatedTokenAccountAddress(
          context.options.wallet.publicKey,
          token,
        );
        claimIx = await context.catapultProgram.methods
          .claimTokenPayload()
          .accounts({
            claimant: context.options.wallet.publicKey,
            claimantItemTokenAccount,
            itemNumber,
            claimantTokenPayload,
            custodiedTokenPayload,
            collectionNumbering,
            catapult,
            launch,
          })
          .instruction();
      } else {
        claimIx = await context.catapultProgram.methods
          .claimNativePayload()
          .accounts({
            claimant: context.options.wallet.publicKey,
            claimantItemTokenAccount,
            itemNumber,
            collectionNumbering,
            catapult,
            launch,
          })
          .instruction();
      }

      const tx = new Transaction();
      tx.add(claimIx);
      const signature = await context.provider.send(tx);
      return { signature };
    };

    /*
      HELPER FUNCTIONS
    */

    const _groupPayloadsByLaunchIndex = (payloads: PayloadMemoAccount[]) => {
      return payloads.reduce(
        (result: Record<string, PayloadMemoAccount[]>, currentValue) => {
          const key = currentValue.launchIndex.toNumber();
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(currentValue);
          return result;
        },
        {},
      );
    };

    const _fetchAllPayloadMemosBySourceWallet = async (
      walletAddress?: PublicKey,
    ): Promise<PayloadMemoAccount[]> => {
      if (!walletAddress && !wallet) {
        throw new Error('No wallet provided.');
      }
      const walletToUse = walletAddress ? walletAddress : wallet;
      if (!walletToUse) {
        throw new Error('No wallet provided.');
      }
      const bytes = bs58.encode(
        Buffer.concat([catapult.toBuffer(), walletToUse.toBuffer()]),
      );
      const offset = 8;
      const config = {
        filters: [
          {
            memcmp: {
              bytes: bytes,
              offset: offset,
            },
          },
        ],
      };
      const responses = await context.provider.connection.getProgramAccounts(
        CATAPULT_PROGRAM_ID,
        config,
      );
      const memos = responses.map((response) => {
        return {
          key: response.pubkey,
          data: context.catapultProgram.coder.accounts.decode(
            'payloadMemo',
            response.account.data,
          ) as PayloadMemoAccount,
        };
      });
      return memos.map((memo) => memo.data);
    };

    const _fetchNftMetadataFromLandedOn = async (
      landedOn: BN,
    ): Promise<Nft | undefined> => {
      let winner: Nft | undefined = undefined;
      if (landedOn !== undefined && landedOn !== null) {
        const itemNumberAttribution = await findItemNumberAttribution(
          collectionNumbering,
          landedOn,
        );
        const itemNumberAttributionMetadata =
          await context.catapultProgram.account.itemNumberAttribution.fetch(
            itemNumberAttribution,
          );
        winner = await context.metaplex
          .nfts()
          .findByMint(itemNumberAttributionMetadata.mintKey);
      }
      return winner;
    };

    const _nextLaunchKey = async () => {
      const nextIndex = await _nextLaunchIndex();
      return findLaunch(catapult, nextIndex);
    };

    const _nextLaunchIndex = async () => {
      const catapultInfo = await context.catapultProgram.account.catapult.fetch(
        catapult,
      );
      return catapultInfo.numLaunches;
    };

    const _fetchNumLaunches = async (): Promise<number> => {
      const catapultMetadata =
        await context.catapultProgram.account.catapult.fetch(catapult);
      return catapultMetadata.numLaunches.toNumber();
    };

    const _fetchAllLaunchKeys = async () => {
      const numLaunches = await _fetchNumLaunches();
      const promises = [];
      for (let i = 0; i < numLaunches; i++) {
        promises.push(findLaunch(catapult, new BN(i)));
      }
      return Promise.all(promises);
    };

    const _fetchAllPayloadMemosFromLaunch = async (
      launch: PublicKey,
    ): Promise<PayloadMemoAccount[]> => {
      const launchMetadata = await context.catapultProgram.account.launch.fetch(
        launch,
      );
      const numPayloads = launchMetadata.numPayloads;
      const promises = [];
      for (let i = numPayloads - 1; i > -1; i--) {
        const key = await findPayloadMemo(launch, i);
        promises.push(context.catapultProgram.account.payloadMemo.fetch(key));
      }
      return Promise.all(promises);
    };

    const _fetchAllLaunchesLandedOnItem = async (mint: PublicKey) => {
      const itemNumberAddress = await findItemNumber(collectionNumbering, mint);
      const itemNumberMetadata =
        await context.catapultProgram.account.itemNumber.fetch(
          itemNumberAddress,
        );
      const itemNumber = itemNumberMetadata.value.toNumber();
      const config = {
        filters: [
          {
            memcmp: {
              bytes: bs58.encode(
                Buffer.concat(
                  [
                    new BN(1).toArrayLike(Buffer, 'le', 1),
                    new BN(itemNumber).toArrayLike(Buffer, 'le', 8),
                  ],
                  9,
                ),
              ),
              offset: 25,
            },
          },
        ],
      };
      const responses = await context.provider.connection.getProgramAccounts(
        CATAPULT_PROGRAM_ID,
        config,
      );
      const launches = responses.map((response) => {
        return {
          key: response.pubkey,
          data: context.catapultProgram.coder.accounts.decode(
            'launch',
            response.account.data,
          ),
        };
      });
      const filteredLaunches: PublicKey[] = [];
      launches.map(async (launch) => {
        if (
          itemNumberMetadata.value.toNumber() ===
          launch.data.landedOn.toNumber()
        ) {
          filteredLaunches.push(launch.key);
        }
      });
      return filteredLaunches;
    };

    const _fetchAllRegisteredNftsByWalletOwner = async (
      walletAddress: PublicKey,
    ) => {
      const allNfts = await context.metaplex
        .nfts()
        .findAllByOwner(walletAddress);
      const allNftsForCollection = allNfts.filter(
        (nft) =>
          nft.collection?.verified === true &&
          nft.collection?.key?.toBase58() === collection.toBase58(),
      );
      const allRegisteredNfts = await _filterNftsByRegistered(
        allNftsForCollection,
      );
      return allRegisteredNfts;
    };

    const _filterNftsByRegistered = async (nfts: Nft[]) => {
      const filteredNfts: Nft[] = [];
      for (let i = 0; i < nfts.length; i++) {
        const nft = nfts[i];
        const itemNumberAddress = await findItemNumber(
          collectionNumbering,
          nft.mint,
        );

        try {
          await context.catapultProgram.account.itemNumber.fetch(
            itemNumberAddress,
          );
          filteredNfts.push(nft);
        } catch (error) {
          continue;
        }
      }
      return filteredNfts;
    };

    /*
      Formatters
    */

    const _formatPayload = async (
      payload: PayloadMemoAccount,
      mint?: PublicKey,
    ): Promise<PayloadItem> => {
      if (mint) {
        let nftMetadata: Nft | undefined = undefined;
        try {
          nftMetadata = await context.metaplex.nfts().findByMint(mint);
        } catch (error) {}
        return {
          isNativeSol: false,
          mint,
          amount: payload.amount.toNumber(),
          message: payload.message,
          nftMetadata,
        };
      } else {
        return {
          isNativeSol: true,
          amount: payload.amount.toNumber(),
          message: payload.message,
        };
      }
    };

    const _calculateTimestampFromSlot = async (slot: number) => {
      const currentSlot = await context.provider.connection.getSlot();
      const slotDiff = currentSlot - slot;
      const slotDiffInMilliseconds = slotDiff * 400; // 400ms per slot
      const now = new Date();
      const date = new Date(now.valueOf() - slotDiffInMilliseconds);
      return date.getTime();
    };

    const _formatLaunch = async (
      launch: LaunchAccount,
      payloads: PayloadMemoAccount[],
    ): Promise<LaunchItem> => {
      const winner = await _fetchNftMetadataFromLandedOn(launch.landedOn);
      const launchPublicKey = await findLaunch(catapult, new BN(launch.index));
      if (payloads.length > 0) {
        const formattedPayloads: PayloadItem[] = [];
        for (
          let payloadIndex = 0;
          payloadIndex < payloads.length;
          payloadIndex++
        ) {
          const payload = payloads[payloadIndex];
          const mint =
            payload.mintIndex !== null
              ? launch.mints[payload.mintIndex]
              : undefined;
          const payloadItem = await _formatPayload(payload, mint);
          formattedPayloads.push(payloadItem);
        }
        const estimatedTimestamp = await _calculateTimestampFromSlot(
          launch.landingSlot,
        );
        return {
          launchIndex: launch.index,
          launchPublicKey,
          landedOnNumber: launch.landedOn,
          winnerNft: winner,
          source: payloads[0].sourceWallet,
          payloads: formattedPayloads,
          numClaims: launch.numClaims,
          estimatedTimestamp,
        };
      }
      throw new Error('No Payload associated with this launch');
    };

    return {
      registerCollection,
      registerItem,
      launchNativePayload,
      launchTokenPayload,
      isReadyForLanding,
      land,
      getLaunchFeed,
      fetchWalletData,
      getAllItemsForRegistration,
      claim,
    };
  };
};

export default createCatapultModule;
