import * as anchor from '@project-serum/anchor';
import {
  TOKEN_PROGRAM_ID,
  createInitializeMintInstruction,
  getMinimumBalanceForRentExemptMint,
  MINT_SIZE,
} from '@solana/spl-token';
import {
  Connection,
  PublicKey,
  SystemProgram,
  TransactionInstruction,
} from '@solana/web3.js';
import { COMPUTE_BUDGET_PROGRAM_ID } from '../constants';

export const createMintInstructions = async (
  payer: PublicKey,
  mint: PublicKey,
  mintAuthority: PublicKey,
  connection: Connection,
) => {
  const lamports = await getMinimumBalanceForRentExemptMint(connection);
  return [
    SystemProgram.createAccount({
      fromPubkey: payer,
      newAccountPubkey: mint,
      space: MINT_SIZE,
      lamports,
      programId: TOKEN_PROGRAM_ID,
    }),
    createInitializeMintInstruction(
      mint,
      0,
      mintAuthority,
      null,
      TOKEN_PROGRAM_ID,
    ),
  ];
};
export const additionalComputeBudgetIx = async ({
  multiplier = 2,
}: {
  multiplier?: number;
}) => {
  const data = Buffer.from(
    Uint8Array.of(
      0,
      ...new anchor.BN(200000 * multiplier).toArray('le', 4),
      ...new anchor.BN(0).toArray('le', 4),
    ),
  );
  return new TransactionInstruction({
    keys: [],
    programId: COMPUTE_BUDGET_PROGRAM_ID,
    data,
  });
};
