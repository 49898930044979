import { PublicKey } from '@solana/web3.js';

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
);

export const FOLLOWER_COLLECTION_PROGRAM_ID = new PublicKey(
  '342zaQ1jgejKvPMqcnZejuZ845NtnfkpGvo9j15gDmEL',
);

export const MINT_REQUEST_PROGRAM_ID = new PublicKey(
  'ABU9Uy4Q5xjLXFnsqu3GjUFS8ZqDtFLUExUDyL5q1hfD',
);

export const COLLECTION_DISTRIBUTOR_PROGRAM_ID = new PublicKey(
  '6rQQ3fRCYo9JNJQGAMX528GNRHVrqA1oEKZ6VKsxcWHi',
);

export const DEFAULT_BASE_TOKEN = new PublicKey(
  'EXxU2Qn8ob1E3bxnP4wJMyS3hiVXMjfhhkS32dDVJZSH',
);

export const DROP_TOKENS_PROGRAM_ID = new PublicKey(
  'Acnnt5yyKqeHMJpshbnXnM33z9bkfbkTJDw1STkE8fTJ',
);

export const COMPUTE_BUDGET_PROGRAM_ID = new PublicKey(
  'ComputeBudget111111111111111111111111111111',
);

export const CATAPULT_PROGRAM_ID = new PublicKey(
  'BVQS2ArK7x9au2XrfTiRnkk8DaKoe6LBtEHAfwKTtYos',
);

export const NUMBERING_NAME = 'fc'; //hardocded in fc program

export const RESERVE_NAME = 'fc_default'; //hardocded in fc program

export const DROP_TOKENS_DECIMAL_MODIFIER = Math.pow(10, 6);

export const USDC_MULTIPLER = Math.pow(10, 6);

export const METADATA_SEED = 'metadata';

export const EDITION_SEED = 'edition';

export const COLLECTION_PATROL_SEED = 'collection_patrol';

export const COLLECTION_NUMBERING_SEED = 'numbering';

export const COLLECTION_MINT_CONFIG_SEED = 'mint_config';

export const ITEM_ID_SEED = 'id';

export const DROP_TOKENS_PATROL_SEED = 'patrol';
