function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { appConfig } from "client/config/app-config";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { isObject, keyBy, transform } from "lodash";
import range from "lodash/range";
import { DATE_FORMAT } from "universal/utils/constants";
export var sum = function(a, b) {
    return a + b;
};
// Get req url that made call to next js.
export var getUrlWithProtocol = function(req) {
    var protocol = "https:";
    var host = req ? req.headers["host"] : window.location.hostname;
    if (host && (host === null || host === void 0 ? void 0 : host.indexOf("localhost")) > -1) {
        protocol = "http:";
    }
    return "".concat(protocol, "//").concat(host);
};
/**
 * Determines if an asset is hosted on a safe host.
 * @param url asset url to process
 */ export var isSafeHostedAsset = function(url) {
    if (typeof url !== "string") return false;
    return url.startsWith(appConfig.HARMONIC_ASSETS_URL) || url.startsWith(appConfig.MOONBASE_ASSETS_URL);
};
/**
 * Takes two dates and returns a formatted range string. Ex. Jan 2020 - Mar 2021
 */ export var formatDateRange = function(startDate, endDate) {
    var dateFormat = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "MMM yyyy";
    if (!startDate && !endDate) return "";
    return "".concat(startDate ? format(new Date(startDate), dateFormat) : "Current", " - ").concat(endDate ? format(new Date(endDate), dateFormat) : "Current");
};
/**
 * Takes one iso string and returns a formatted range string in UTC.
 * Ex. 2014-01-01T00:00:00Z => 01/01/2014
 *
 * If we left the hh:mm:sssZ JS date would convert it to local time
 * where the INCORRECT output would be 12/31/2013.
 *
 */ export var parseISOToUTCWithFormat = function(dateInput) {
    var dateFormat = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : DATE_FORMAT;
    // a full ISO with times will be parsed as local, where only the date will be parsed as UTC
    return format(parseISO("".concat(dateInput.split("T")[0])), dateFormat);
};
/* Returns array of year options for Select Input.*/ export var generateYearSelectOptions = function(startYear, endYear) {
    return range(startYear, endYear + 1).map(function(year) {
        return {
            label: "".concat(year),
            value: year
        };
    }).sort(function(a, b) {
        return b.value - a.value;
    });
};
/**
 * Truncates and formats a money value e.g. 3250000 -> $3.25M
 * @param amount money amount to truncate
 */ export var truncateMoneyValue = function(amount) {
    return !amount ? "" : amount < 10000 ? "$".concat(amount) : amount < 1000000 ? "$".concat((amount / 1000).toFixed(2), "K") : amount < 1000000000 ? "$".concat((amount / 1000000).toFixed(2), "M") : "$".concat((amount / 1000000000).toFixed(2), "B");
};
export var getPlusSymbol = function(value) {
    if (!value || value === 0 || value < 0) return "";
    return "+";
};
/**
 * Returns a number abbreviated.
 * @param num asset number to process
 */ export var numberSuffixFormatter = function(num) {
    var numberSuffixes = [
        {
            value: 1,
            symbol: ""
        },
        {
            value: 1000,
            symbol: "K"
        },
        {
            value: 1000000,
            symbol: "M"
        },
        {
            value: 1000000000,
            symbol: "B"
        },
        {
            value: 1000000000000,
            symbol: "T"
        },
        {
            value: 1000000000000000,
            symbol: "P"
        },
        {
            value: 1000000000000000000,
            symbol: "E"
        }, 
    ];
    var formattingRegx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for(i = numberSuffixes.length - 1; i > 0; i--){
        if (num >= numberSuffixes[i].value) {
            break;
        }
    }
    return "".concat((num / numberSuffixes[i].value).toFixed(1).replace(formattingRegx, "$1")).concat(numberSuffixes[i].symbol);
};
// Removes all iteration of keys within deep nested object/arrays
export var deepOmit = function(obj1, keysToOmit) {
    var keysToOmitIndex = keyBy(Array.isArray(keysToOmit) ? keysToOmit : [
        keysToOmit
    ]); // create an index object of the keys that should be omitted
    function omitFromObject(obj) {
        // the inner function which will be called recursivley
        return transform(obj, function(result, value, key) {
            // transform to a new object
            if (key in keysToOmitIndex) {
                // if the key is in the index skip it
                return;
            }
            result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
        });
    }
    return omitFromObject(obj1); // return the inner function result
};
// Appends https protocol if it does not exist.
export var withHttp = function(url) {
    return url === null || url === void 0 ? void 0 : url.replace(/^(?:(.*:)?\/\/)?(.*)/i, function(match, schemma, nonSchemmaUrl) {
        return schemma ? match : "http://".concat(nonSchemmaUrl);
    });
};
export var isValidWebUrl = function(url) {
    if (!url) return false;
    var regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return regEx.test(url);
};
export var timeFromNow = function(date) {
    var seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);
    var intervalType;
    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = "year";
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = "month";
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = "day";
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = "hour";
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = "minute";
                    } else {
                        interval = seconds;
                        intervalType = "second";
                    }
                }
            }
        }
    }
    if (interval > 1 || interval === 0) {
        intervalType += "s";
    }
    return interval + " " + intervalType;
};
/**
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#output-encoding
 * Data type: String
 * Context: Untrusted URL in a SRC or HREF attribute
 * Example: <a href="UNTRUSTED URL ">clickme</a> <iframe src="UNTRUSTED URL " />
 * Defense: Canonicalize input, URL Validation, Safe URL verification,
 * Allow-list http and HTTPS URLs only (Avoid the JavaScript Protocol to
 * Open a new Window), Attribute encoder.
 */ export function isValidHttpUrl(url) {
    var outputUrl;
    try {
        outputUrl = new URL(url);
    } catch (_) {
        return false;
    }
    return outputUrl.protocol === "http:" || outputUrl.protocol === "https:";
}
export var decimalsToFormattedPercentage = function(decimals) {
    var percentage = decimals * 100;
    return "".concat(percentage.toFixed(2).toString(), "%");
};
// [1,2,3,4,7,11,45,46,47] => "1-4, 7, 11, 45-47"
export var formatNumberList = function(numbers) {
    var groups = numbers.reduce(function(state, number, index) {
        if (index === 0) {
            state.currentGroupStart = number;
        }
        var nextNumber = index === numbers.length - 1 ? 0 : numbers[index + 1];
        if (nextNumber !== number + 1) {
            var group = [
                state.currentGroupStart
            ];
            if (number !== state.currentGroupStart) {
                group.push(number);
            }
            state.groups.push(group);
            state.currentGroupStart = nextNumber;
        }
        return state;
    }, {
        currentGroupStart: 0,
        groups: []
    }).groups;
    return groups.map(function(group) {
        return group.join("-");
    }).join(", ");
};
// Converts object to query params
export var objectToQueryParams = function(object) {
    var params = Object.entries(object).filter(function(param) {
        var _param = _slicedToArray(param, 2), value = _param[1];
        return value !== undefined && value !== null;
    }).map(function(param) {
        var _param = _slicedToArray(param, 2), key = _param[0], value = _param[1];
        return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(String(value)));
    });
    return params.length > 0 ? "?".concat(params.join("&")) : "";
};
export var isMobileOrTablet = function() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};
export var disableGlobalScrollbar = function() {
    document.documentElement.style.overflow = "hidden";
    if (window.innerWidth > 500) {
        document.body.style.paddingRight = "15px";
    }
};
export var enableGlobalScrollbar = function() {
    document.documentElement.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
};
