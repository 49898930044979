import { Context } from '../context';
import createAirdropModule from './airdrop';
import createCollectionModule from './collection';
import createClaimModule from './claim';
import createNftModule from './nft';
import createCatapultModule from './catapult';

export default (context: Context) => ({
  airdrop: createAirdropModule(context),
  collection: createCollectionModule(context),
  claim: createClaimModule(context),
  nft: createNftModule(context),
  catapult: createCatapultModule(context),
});
