import React from 'react';

export interface IconProps {
  width?: string | number;
  height?: string | number;
  src: string | React.FC<React.SVGProps<SVGElement>>;
  alt?: string;
  fill?: string;
  stroke?: string;
}

const Icon = ({
  src,
  width = '4',
  height = '4',
  fill,
  alt,
  stroke,
}: IconProps) => {
  const iconComponentProps: Record<string, unknown> = {
    className: `w-${width} h-${height}`,
  };
  if (fill) iconComponentProps.fill = fill;
  if (stroke) iconComponentProps.stroke = stroke;

  const IconComponent = src;
  if (typeof src === 'string') {
    return (
      <img
        className={`object-cover w-${width} h-${height}`}
        src={src}
        alt={alt}
      />
    );
  } else {
    return <IconComponent {...iconComponentProps} role="svg" />;
  }
};

export { Icon };
