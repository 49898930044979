import { FollowerCollectionConfig } from '@moonbase/moonbase-sdk';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

export const createTestFollowerCollectionConfig = ({
  name,
  symbol,
}: {
  name: string;
  symbol: string;
}): FollowerCollectionConfig => ({
  name,
  symbol,
});

export const SOLANA_CLUSTER = WalletAdapterNetwork.Mainnet;
export const QUICK_NODE_MAINNET_RPC_ENDPOINT =
  'https://winter-holy-violet.solana-mainnet.quiknode.pro/';
