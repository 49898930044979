// @ts-nocheck
import { appConfig } from "client/config/app-config";
import { init } from "next-firebase-auth";
import { DEFAULT_LOGGED_IN_ROUTE } from "universal/utils/constants";
var initAuth = function() {
    init({
        debug: true,
        authPageURL: "/login",
        appPageURL: DEFAULT_LOGGED_IN_ROUTE,
        loginAPIEndpoint: "/api/auth/login",
        logoutAPIEndpoint: "/api/auth/logout",
        // Required in most cases.
        firebaseAdminInitConfig: {
            credential: {
                projectId: appConfig.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
                clientEmail: appConfig.NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL,
                // The private key must not be accesssible on the client side.
                privateKey: process.env.FIREBASE_PRIVATE_KEY
            },
            databaseURL: appConfig.NEXT_PUBLIC_FIREBASE_DATABASE_URL
        },
        firebaseClientInitConfig: {
            apiKey: appConfig.NEXT_PUBLIC_FIREBASE_API_KEY,
            authDomain: appConfig.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
            databaseURL: appConfig.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
            projectId: appConfig.NEXT_PUBLIC_FIREBASE_PROJECT_ID
        },
        cookies: {
            name: "moonbaseApp",
            // Keys are required unless you set `signed` to `false`.
            // The keys cannot be accessible on the client side.
            keys: [
                process.env.COOKIE_SECRET_CURRENT,
                process.env.COOKIE_SECRET_PREVIOUS, 
            ],
            httpOnly: true,
            maxAge: 12 * 60 * 60 * 24 * 1000,
            overwrite: true,
            path: "/",
            sameSite: "strict",
            secure: true,
            signed: true
        },
        onLoginRequestError: function(err) {
            console.error(err);
        },
        onLogoutRequestError: function(err) {
            console.error(err);
        },
        onVerifyTokenError: function(err) {
            console.error(err);
        },
        onTokenRefreshError: function(err) {
            console.error(err);
        }
    });
};
export default initAuth;
