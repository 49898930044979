import { PublicKey, TransactionResponse } from '@solana/web3.js';
import { existsInTransactionLogs } from '@moonbase/solana-utils';
export enum TransactionType {
  Airdrop,
  // TODO: Add more transaction types here
}

export const KnownPrograms = {
  tokenProgram: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
};

export const getTransactionType = (
  transaction: TransactionResponse,
): TransactionType | null => {
  if (existsInTransactionLogs(transaction, 'Instruction: Deposit')) {
    return TransactionType.Airdrop;
  } else {
    return null;
  }
};
export const filterTransactionsByType = (
  transactions: (TransactionResponse | null)[],
  type: TransactionType,
): (TransactionResponse | null)[] => {
  return transactions.filter((t) => {
    if (!t) {
      return false;
    }
    return getTransactionType(t) === type;
  });
};
