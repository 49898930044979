export const calculateClaimPercentage = (followerNumber: number, totalSupply: number) => {
  const defaultCurveParameter = 1;
  if (followerNumber < 1) {
    throw new Error("Invalid follower number. Must be greater than 0.");
  }
  const areaUnderTheCurveForItem = Math.log(
    (followerNumber + defaultCurveParameter) / followerNumber
  );
  const areaUnderTheWholeCurve = Math.log(totalSupply + defaultCurveParameter);
  const currentStake = areaUnderTheCurveForItem / areaUnderTheWholeCurve;
  return currentStake;
};

export const calculateTotalClaimable = (
  claimPercentage: number,
  allTimeAirdropsToCollection: number,
  totalClaimedByItem: number
) => {
  return claimPercentage * allTimeAirdropsToCollection - totalClaimedByItem;
};

export const calculateTotalAirdropForCollection = (
  collectionReserveBalance: number,
  prevTokenReserves: number,
  cumulativeTokenReserves: number
) => {
  // Token reserve gets updated on claims.
  // If no claims have occured, the total airdrop amount is equal to the collection reserve balance
  if (!cumulativeTokenReserves) {
    return collectionReserveBalance;
  }

  let totalAirdropAmount = 0;

  // Check if more has been airdropped after previous claim
  if (collectionReserveBalance > prevTokenReserves) {
    totalAirdropAmount =
      collectionReserveBalance + cumulativeTokenReserves - prevTokenReserves;
  } else {
    totalAirdropAmount = cumulativeTokenReserves;
  }
  return totalAirdropAmount;
};
