function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var DATADOG_CONFIG = {
    DATADOG_API_KEY: process.env.DATADOG_API_KEY
};
var HARMONIC_CONFIG = {
    HARMONIC_API_GRAPHQL_KEY: process.env.HARMONIC_API_GRAPHQL_KEY
};
var MOONBASE_EMAIL_CONFIG = {
    MOONBASE_CONFIRMATION_EMAIL_TEMPLATE_ID: "d-34c0f23238e6449787b24c729be7acb2",
    MOONBASE_WAITLIST_ID: "eccf77d5-3fa2-491c-a09f-435005e44411",
    MOONBASE_WELCOME_EMAIL_TEMPLATE_ID: "d-661906a9893c462a97be6de8e21e00fa"
};
var PRODUCTION = _objectSpread({}, HARMONIC_CONFIG, MOONBASE_EMAIL_CONFIG, DATADOG_CONFIG, {
    HARMONIC_API_GRAPHQL_ENDPOINT: "https://api.harmonic.ai:443/graphql",
    SLACK_GROWTH_WEBHOOK_URL: "https://hooks.slack.com/services/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"
});
var DEVELOPMENT = _objectSpread({}, HARMONIC_CONFIG, MOONBASE_EMAIL_CONFIG, DATADOG_CONFIG, {
    HARMONIC_API_GRAPHQL_ENDPOINT: "https://api.staging.harmonic.ai:443/graphql",
    SLACK_GROWTH_WEBHOOK_URL: "https://hooks.slack.com/services/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"
});
export var apiConfig = _objectSpread({}, process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? PRODUCTION : DEVELOPMENT);
