import { Wallet } from '@project-serum/anchor';
import { ConfirmOptions, Connection } from '@solana/web3.js';
import createContext from './context';
import createModules from './modules';
import createCatapultModule from './modules/catapult';

export * from './utils';
export * from './types';
export * from './constants';

export type SdkOptions = {
  connection: Connection;
  wallet: Wallet;
  opts?: ConfirmOptions;
  logger?: any;
};

export type MoonbaseSdk = ReturnType<typeof createModules>;
export type CatapultModule = Awaited<
  ReturnType<ReturnType<typeof createCatapultModule>>
>;

export default (options: SdkOptions): MoonbaseSdk => {
  const context = createContext(options);
  const modules = createModules(context);

  return {
    ...modules,
  };
};
