import * as anchor from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  TOKEN_METADATA_PROGRAM_ID,
  FOLLOWER_COLLECTION_PROGRAM_ID,
  METADATA_SEED,
  EDITION_SEED,
  COLLECTION_PATROL_SEED,
  COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  DROP_TOKENS_PROGRAM_ID,
  DROP_TOKENS_PATROL_SEED,
  MINT_REQUEST_PROGRAM_ID,
  CATAPULT_PROGRAM_ID,
} from '../constants';
import { bytes } from '@project-serum/anchor/dist/cjs/utils';
import { Program } from '@project-serum/anchor';
import { Catapult } from '../generated';
import * as SplToken from '@solana/spl-token';

export const findDropPatrolAddress = async (mint: PublicKey) => {
  return PublicKey.findProgramAddress(
    [anchor.utils.bytes.utf8.encode(DROP_TOKENS_PATROL_SEED), mint.toBuffer()],
    DROP_TOKENS_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findCollectionPatrol = async (collectionMint: PublicKey) => {
  return PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode(COLLECTION_PATROL_SEED),
      collectionMint.toBuffer(),
    ],
    FOLLOWER_COLLECTION_PROGRAM_ID,
  ).then(([address, bump]) => {
    return {
      address: address,
      bump: bump,
    };
  });
};

export const findAssociatedTokenAccountAddress = async (
  owner: PublicKey,
  mint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
    ASSOCIATED_TOKEN_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findAssociatedTokenAccountPda = async (
  owner: PublicKey,
  mint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
    ASSOCIATED_TOKEN_PROGRAM_ID,
  ).then(([address, bump]) => {
    return { address, bump };
  });
};

export const findMetadataAddress = async (mint: PublicKey) => {
  return PublicKey.findProgramAddress(
    [
      Buffer.from(METADATA_SEED),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
    ],
    TOKEN_METADATA_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findMetadataPda = async (mint: PublicKey) => {
  return PublicKey.findProgramAddress(
    [
      Buffer.from(METADATA_SEED),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
    ],
    TOKEN_METADATA_PROGRAM_ID,
  ).then(([address, bump]) => {
    return { address, bump };
  });
};

export const findMasterEditionAddress = async (mint: PublicKey) => {
  return PublicKey.findProgramAddress(
    [
      Buffer.from(METADATA_SEED),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from(EDITION_SEED),
    ],
    TOKEN_METADATA_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findMasterEditionPda = async (mint: PublicKey) => {
  return PublicKey.findProgramAddress(
    [
      Buffer.from(METADATA_SEED),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from(EDITION_SEED),
    ],
    TOKEN_METADATA_PROGRAM_ID,
  ).then(([address, bump]) => {
    return { address, bump };
  });
};

export const findMintRequestAddress = async (
  requestor: PublicKey,
  acceptor: PublicKey,
  collectionMint: PublicKey,
  redeemAuthority: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [
      bytes.utf8.encode('mint_request'),
      requestor.toBuffer(),
      acceptor.toBuffer(),
      collectionMint.toBuffer(),
      redeemAuthority.toBuffer(),
    ],
    MINT_REQUEST_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findCollectionNumberingAddress = async (
  collectionMint: PublicKey,
  name: string,
) => {
  return PublicKey.findProgramAddress(
    [
      bytes.utf8.encode('numbering'),
      collectionMint.toBuffer(),
      new Uint8Array([1]),
      bytes.utf8.encode(name),
    ],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findCollectionNumberingPda = async (
  collectionMint: PublicKey,
  name: string,
) => {
  return PublicKey.findProgramAddress(
    [
      bytes.utf8.encode('numbering'),
      collectionMint.toBuffer(),
      bytes.utf8.encode(name),
    ],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address, bump]) => {
    return {
      address: address,
      bump: bump,
    };
  });
};
export const findDepositTrackerAddress = async (
  reserve: PublicKey,
  user: PublicKey,
) => {
  return await PublicKey.findProgramAddress(
    [Buffer.from('deposit-tracker'), reserve.toBuffer(), user.toBuffer()],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findMetadataArtifactAddress = async (
  itemMint: PublicKey,
  follower: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [itemMint.toBuffer(), follower.toBuffer()],
    FOLLOWER_COLLECTION_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findCollectionReserveAddress = async (
  numbering: PublicKey,
  name: string,
) => {
  // TODO: Add proper to_seed_format style processing of the name parameter
  return PublicKey.findProgramAddress(
    [
      bytes.utf8.encode('reserve'),
      numbering.toBuffer(),
      new Uint8Array([1]),
      bytes.utf8.encode(name.toLowerCase()),
    ],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findTokenItemClaimAccountAddress = async (
  collectionReserve: PublicKey,
  itemId: PublicKey,
  tokenMint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [
      bytes.utf8.encode('claim'),
      collectionReserve.toBuffer(),
      itemId.toBuffer(),
      tokenMint.toBuffer(),
    ],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findNativeItemClaimAccountAddress = async (
  collectionReserve: PublicKey,
  itemId: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [
      bytes.utf8.encode('claim'),
      collectionReserve.toBuffer(),
      itemId.toBuffer(),
      bytes.utf8.encode('native'),
    ],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findCollectionTokenReserveAddress = async (
  reserveAddress: PublicKey,
  mintAddress: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [
      bytes.utf8.encode('token_reserve'),
      reserveAddress.toBuffer(),
      mintAddress.toBuffer(),
    ],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findItemIdAddress = async (
  numbering: PublicKey,
  itemMint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [bytes.utf8.encode('id'), numbering.toBuffer(), itemMint.toBuffer()],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findItemIdPda = async (
  numbering: PublicKey,
  itemMint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [bytes.utf8.encode('id'), numbering.toBuffer(), itemMint.toBuffer()],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address, bump]) => {
    return { address, bump };
  });
};
export const findFollowerCollectionConfigAddress = async (
  collectionMint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [anchor.utils.bytes.utf8.encode('config'), collectionMint.toBuffer()],
    FOLLOWER_COLLECTION_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findFollowerCollectionConfig = async (
  collectionMint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [anchor.utils.bytes.utf8.encode('config'), collectionMint.toBuffer()],
    FOLLOWER_COLLECTION_PROGRAM_ID,
  ).then(([address, bump]) => {
    return {
      address: address,
      bump: bump,
    };
  });
};

export const findCollectionPatrolAddress = async (
  collectionMint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode('collection_patrol'),
      collectionMint.toBuffer(),
    ],
    FOLLOWER_COLLECTION_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findTokenReserveAddress = async (
  reserveAddress: PublicKey,
  mintAddress: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [
      Buffer.from('token_reserve'),
      reserveAddress.toBuffer(),
      mintAddress.toBuffer(),
    ],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findMemoAddress = async (
  reserve: PublicKey,
  depositor: PublicKey,
  nonce: number,
) => {
  const nonceBytes = new anchor.BN(nonce).toArrayLike(Buffer, 'le', 4);
  return await PublicKey.findProgramAddress(
    [Buffer.from('memo'), reserve.toBuffer(), depositor.toBuffer(), nonceBytes],
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

/*
  CATAPULT LOCATORS
*/

export const findCollectionNumbering = async (collectionMint: PublicKey) => {
  return PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode('collection_numbering'),
      collectionMint.toBuffer(),
    ],
    CATAPULT_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findItemNumber = async (
  numbering: PublicKey,
  itemMint: PublicKey,
) => {
  return PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode('item_number'),
      numbering.toBuffer(),
      itemMint.toBuffer(),
    ],
    CATAPULT_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
export const findCatapult = async (numbering: PublicKey) => {
  return PublicKey.findProgramAddress(
    [anchor.utils.bytes.utf8.encode('catapult'), numbering.toBuffer()],
    CATAPULT_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findNextLaunch = async (
  catapult: PublicKey,
  catapultProgram: Program<Catapult>,
) => {
  const catapultInfo = await catapultProgram.account.catapult.fetch(catapult);
  const nextLaunchIndex = catapultInfo.numLaunches;
  return findLaunch(catapult, nextLaunchIndex);
};

export const findLaunch = async (
  catapult: PublicKey,
  launchIndex: anchor.BN,
) => {
  const launchIndexBytes = launchIndex.toArrayLike(Buffer, 'le', 8);
  return PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode('launch'),
      catapult.toBuffer(),
      Buffer.from(launchIndexBytes),
    ],
    CATAPULT_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const createLaunchAddress = async (
  catapult: PublicKey,
  launchIndex: anchor.BN,
  bump: number,
) => {
  return PublicKey.createProgramAddress(
    [
      anchor.utils.bytes.utf8.encode('launch'),
      catapult.toBuffer(),
      Buffer.from(launchIndex.toArrayLike(Buffer, 'le', 8)),
      new Uint8Array([bump]),
    ],
    CATAPULT_PROGRAM_ID,
  ).then((address) => {
    return address;
  });
};

export const findAtaCustodiedTokenPayload = async (
  tokenMint: PublicKey,
  launch: PublicKey,
) => {
  return SplToken.getAssociatedTokenAddress(tokenMint, launch, true);
};

export const findPayloadMemo = async (
  launch: PublicKey,
  payloadIndex: number,
) => {
  return PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode('payload_memo'),
      launch.toBuffer(),
      new anchor.BN(payloadIndex).toArrayLike(Buffer, 'le', 2),
    ],
    CATAPULT_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};

export const findItemNumberAttribution = async (
  numbering: PublicKey,
  number: anchor.BN,
) => {
  return PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode('in_attribution'),
      numbering.toBuffer(),
      number.toArrayLike(Buffer, 'le', 8),
    ],
    CATAPULT_PROGRAM_ID,
  ).then(([address]) => {
    return address;
  });
};
