export type FollowerCollection = {
  "version": "0.1.0",
  "name": "follower_collection",
  "instructions": [
    {
      "name": "createCollectionMetadata",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionPatrol",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "followerCollectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMasterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "metadataArgs",
          "type": {
            "defined": "CollectionMetadataArgs"
          }
        }
      ]
    },
    {
      "name": "initializeCollection",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "followerCollectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "creatorTokenAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "communityTokenAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionReserve",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDistributorProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "initializeArgs",
          "type": {
            "defined": "InitializeArgs"
          }
        }
      ]
    },
    {
      "name": "createFollowerMetadata",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "payerTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "follower",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "itemMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "followerItemTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemId",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "followerCollectionConfig",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "creatorWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "communityWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "creatorTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "communityTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMasterEdition",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionDistributorProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "initializeFollow",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "itemMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemMasterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "itemId",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "changeCollectionConfig",
      "accounts": [
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "followerCollectionConfig",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "ChangeCollectionConfigArgs"
          }
        }
      ]
    },
    {
      "name": "changeCollectionMaxSupply",
      "accounts": [
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "followerCollectionConfig",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDistributorProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "newMaxSupply",
          "type": {
            "option": "u64"
          }
        }
      ]
    },
    {
      "name": "expandCollectionConfig",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createConfigV1",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "followerCollectionConfig",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "creator",
            "type": "publicKey"
          },
          {
            "name": "communityReserve",
            "type": "publicKey"
          },
          {
            "name": "creatorTokenAccount",
            "type": "publicKey"
          },
          {
            "name": "communityTokenAccount",
            "type": "publicKey"
          },
          {
            "name": "mintPrice",
            "type": "u64"
          },
          {
            "name": "communityMintShare",
            "type": "u16"
          },
          {
            "name": "secondaryRoyalty",
            "type": "u16"
          },
          {
            "name": "communitySecondaryRoyaltyShare",
            "type": "u8"
          },
          {
            "name": "name",
            "type": "string"
          },
          {
            "name": "symbol",
            "type": "string"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "expectsNativePayment",
            "type": "bool"
          },
          {
            "name": "requiresMintRequest",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "patrol",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "ChangeCollectionConfigArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "newCreator",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newCommunityReserve",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newCreatorTokenAccount",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newCommunityTokenAccount",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newExpectsNativePayment",
            "type": {
              "option": "bool"
            }
          },
          {
            "name": "newMintPrice",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "newCommunityMintShare",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "newSecondaryRoyalty",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "newCommunitySecondaryRoyaltyShare",
            "type": {
              "option": "u8"
            }
          }
        ]
      }
    },
    {
      "name": "CollectionMetadataArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "name",
            "type": "string"
          },
          {
            "name": "symbol",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "InitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "mintPrice",
            "type": "u64"
          },
          {
            "name": "communityMintShare",
            "type": "u16"
          },
          {
            "name": "secondaryRoyalty",
            "type": "u16"
          },
          {
            "name": "communitySecondaryRoyaltyShare",
            "type": "u8"
          },
          {
            "name": "maxSupply",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "expectsNativePayment",
            "type": "bool"
          },
          {
            "name": "requiresMintRequest",
            "type": "bool"
          },
          {
            "name": "reserveCurveParamater",
            "type": "u16"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "StringTooBig",
      "msg": "string is too big, must be less than 20 bytes "
    },
    {
      "code": 6001,
      "name": "NonZeroCommunityMintShare",
      "msg": "community mint share must be zero"
    },
    {
      "code": 6002,
      "name": "NonZeroCommunityRoyaltyShare",
      "msg": "community mint share must be zero"
    },
    {
      "code": 6003,
      "name": "BasisPointValue",
      "msg": "value must be in basis points"
    },
    {
      "code": 6004,
      "name": "InvalidPercentage",
      "msg": "percentage must be from 0-100"
    },
    {
      "code": 6005,
      "name": "MintRequest",
      "msg": "collection requires mint request"
    },
    {
      "code": 6006,
      "name": "MintRequestRemainingAccountsMissing",
      "msg": "collection requires mint request, but 0 remaining accounts were passed. Pass accepted mint request and mint request program in remaining accts"
    },
    {
      "code": 6007,
      "name": "InvalidMintRequestAccount",
      "msg": "Could not deserialize first remaining account into valid mint request account"
    },
    {
      "code": 6008,
      "name": "InvalidMintRequestProgramAccount",
      "msg": "Could not deserialize second remaining account into valid mint request program account"
    },
    {
      "code": 6009,
      "name": "MintRequestPubkeyMismatch",
      "msg": "Mint request account pubkey does not match expected for this collection. check seeds"
    }
  ]
};

export const IDL: FollowerCollection = {
  "version": "0.1.0",
  "name": "follower_collection",
  "instructions": [
    {
      "name": "createCollectionMetadata",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionPatrol",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "followerCollectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMasterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "metadataArgs",
          "type": {
            "defined": "CollectionMetadataArgs"
          }
        }
      ]
    },
    {
      "name": "initializeCollection",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "followerCollectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "creatorTokenAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "communityTokenAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionReserve",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDistributorProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "initializeArgs",
          "type": {
            "defined": "InitializeArgs"
          }
        }
      ]
    },
    {
      "name": "createFollowerMetadata",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "payerTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "follower",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "itemMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "followerItemTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemId",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "followerCollectionConfig",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "creatorWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "communityWallet",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "creatorTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "communityTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMasterEdition",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionDistributorProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "initializeFollow",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "itemMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "itemMasterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "itemId",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "changeCollectionConfig",
      "accounts": [
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "followerCollectionConfig",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "ChangeCollectionConfigArgs"
          }
        }
      ]
    },
    {
      "name": "changeCollectionMaxSupply",
      "accounts": [
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "followerCollectionConfig",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionPatrol",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionNumbering",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDistributorProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "newMaxSupply",
          "type": {
            "option": "u64"
          }
        }
      ]
    },
    {
      "name": "expandCollectionConfig",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createConfigV1",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "followerCollectionConfig",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "creator",
            "type": "publicKey"
          },
          {
            "name": "communityReserve",
            "type": "publicKey"
          },
          {
            "name": "creatorTokenAccount",
            "type": "publicKey"
          },
          {
            "name": "communityTokenAccount",
            "type": "publicKey"
          },
          {
            "name": "mintPrice",
            "type": "u64"
          },
          {
            "name": "communityMintShare",
            "type": "u16"
          },
          {
            "name": "secondaryRoyalty",
            "type": "u16"
          },
          {
            "name": "communitySecondaryRoyaltyShare",
            "type": "u8"
          },
          {
            "name": "name",
            "type": "string"
          },
          {
            "name": "symbol",
            "type": "string"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "expectsNativePayment",
            "type": "bool"
          },
          {
            "name": "requiresMintRequest",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "patrol",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "ChangeCollectionConfigArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "newCreator",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newCommunityReserve",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newCreatorTokenAccount",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newCommunityTokenAccount",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "newExpectsNativePayment",
            "type": {
              "option": "bool"
            }
          },
          {
            "name": "newMintPrice",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "newCommunityMintShare",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "newSecondaryRoyalty",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "newCommunitySecondaryRoyaltyShare",
            "type": {
              "option": "u8"
            }
          }
        ]
      }
    },
    {
      "name": "CollectionMetadataArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "name",
            "type": "string"
          },
          {
            "name": "symbol",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "InitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "mintPrice",
            "type": "u64"
          },
          {
            "name": "communityMintShare",
            "type": "u16"
          },
          {
            "name": "secondaryRoyalty",
            "type": "u16"
          },
          {
            "name": "communitySecondaryRoyaltyShare",
            "type": "u8"
          },
          {
            "name": "maxSupply",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "expectsNativePayment",
            "type": "bool"
          },
          {
            "name": "requiresMintRequest",
            "type": "bool"
          },
          {
            "name": "reserveCurveParamater",
            "type": "u16"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "StringTooBig",
      "msg": "string is too big, must be less than 20 bytes "
    },
    {
      "code": 6001,
      "name": "NonZeroCommunityMintShare",
      "msg": "community mint share must be zero"
    },
    {
      "code": 6002,
      "name": "NonZeroCommunityRoyaltyShare",
      "msg": "community mint share must be zero"
    },
    {
      "code": 6003,
      "name": "BasisPointValue",
      "msg": "value must be in basis points"
    },
    {
      "code": 6004,
      "name": "InvalidPercentage",
      "msg": "percentage must be from 0-100"
    },
    {
      "code": 6005,
      "name": "MintRequest",
      "msg": "collection requires mint request"
    },
    {
      "code": 6006,
      "name": "MintRequestRemainingAccountsMissing",
      "msg": "collection requires mint request, but 0 remaining accounts were passed. Pass accepted mint request and mint request program in remaining accts"
    },
    {
      "code": 6007,
      "name": "InvalidMintRequestAccount",
      "msg": "Could not deserialize first remaining account into valid mint request account"
    },
    {
      "code": 6008,
      "name": "InvalidMintRequestProgramAccount",
      "msg": "Could not deserialize second remaining account into valid mint request program account"
    },
    {
      "code": 6009,
      "name": "MintRequestPubkeyMismatch",
      "msg": "Mint request account pubkey does not match expected for this collection. check seeds"
    }
  ]
};
