import { Program, Provider } from '@project-serum/anchor';
import { SdkOptions } from '..';
import {
  CATAPULT_PROGRAM_ID,
  COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  FOLLOWER_COLLECTION_PROGRAM_ID,
  MINT_REQUEST_PROGRAM_ID,
} from './constants';
import {
  CD_IDL,
  CollectionDistributor,
  FollowerCollection,
  FC_IDL,
  MR_IDL,
  MintRequest,
  CATAPULT_IDL,
  Catapult,
} from './generated';
import { Metaplex } from '@metaplex-foundation/js-next';

export type Context = {
  provider: Provider;
  options: SdkOptions;
  followerCollectionProgram: Program<FollowerCollection>;
  collectionDistributionProgram: Program<CollectionDistributor>;
  mintRequestProgram: Program<MintRequest>;
  catapultProgram: Program<Catapult>;
  metaplex: Metaplex;
};

const createContext = (options: SdkOptions): Context => {
  const { connection, wallet, opts = {} } = options;
  const provider = new Provider(connection, wallet, opts);
  const followerCollectionProgram = new Program(
    FC_IDL,
    FOLLOWER_COLLECTION_PROGRAM_ID,
    provider,
  );
  const collectionDistributionProgram = new Program(
    CD_IDL,
    COLLECTION_DISTRIBUTOR_PROGRAM_ID,
    provider,
  );
  const mintRequestProgram = new Program(
    MR_IDL,
    MINT_REQUEST_PROGRAM_ID,
    provider,
  );
  const metaplex = new Metaplex(connection);
  const catapultProgram = new Program(
    CATAPULT_IDL,
    CATAPULT_PROGRAM_ID,
    provider,
  );

  return {
    provider,
    options,
    followerCollectionProgram,
    collectionDistributionProgram,
    mintRequestProgram,
    catapultProgram,
    metaplex,
  };
};

export default createContext;
