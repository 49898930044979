import * as anchor from '@project-serum/anchor';
import { Program } from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import * as web3 from '@solana/web3.js';
import { PublicKey } from '@solana/web3.js';
import {
  findAssociatedTokenAccountPda,
  findFollowerCollectionConfig,
  findItemIdPda,
  findMasterEditionPda,
  findMetadataPda,
} from '.';
import { FollowerCollection } from '../generated';
import { GenerateItemRespose } from '../types';
import { TOKEN_METADATA_PROGRAM_ID } from '../constants';

export const tokenMintsForCollection = async (
  collectionMint: PublicKey,
  provider: anchor.Provider,
) => {
  const config = {
    filters: [
      {
        dataSize: 679,
      },
      {
        memcmp: {
          bytes: collectionMint.toBase58(),
          offset: 436,
        },
      },
    ],
  };
  const responses = await provider.connection.getProgramAccounts(
    TOKEN_METADATA_PROGRAM_ID,
    config,
  );
  const filteredResponses = responses.filter((response) => {
    const verified = response.account.data[435];
    return verified === 1;
  });
  return filteredResponses.map((response) => {
    const mint = response.account.data.subarray(33, 65);
    return new PublicKey(mint);
  });
};

export const ownersForTokenMint = async (
  tokenMint: PublicKey,
  provider: anchor.Provider,
) => {
  const config = {
    filters: [
      {
        dataSize: 165, //token account size
      },
      {
        memcmp: {
          bytes: tokenMint.toBase58(),
          offset: 0,
        },
      },
    ],
  };
  const responses = await provider.connection.getProgramAccounts(
    TOKEN_PROGRAM_ID,
    config,
  );
  return responses.map((response) => {
    const tokenAccount = response.pubkey;
    const owner = response.account.data.subarray(32, 64);
    return { tokenAccount: tokenAccount, owner: new PublicKey(owner) };
  });
};

//for each token mint, find the person who owns it
export const holdersForTokenMints = async (
  tokenMints: anchor.web3.PublicKey[],
  provider: anchor.Provider,
) => {
  //for each token mint, find the owner
  return await Promise.all(
    tokenMints.map(async (tokenMint) => {
      return await ownersForTokenMint(tokenMint, provider).then((owners) => {
        return {
          tokenMint: tokenMint,
          tokenAccount: owners[0].tokenAccount,
          owner: owners[0].owner,
        };
      });
    }),
  );
};

export const holdersForCollection = async (
  collectionMint: PublicKey,
  provider: anchor.Provider,
) => {
  const tokenMints = await tokenMintsForCollection(collectionMint, provider);
  return await holdersForTokenMints(tokenMints, provider);
};

export const getFollowerCollectionMetadata = async (
  followerCollectionProgram: Program<FollowerCollection>,
  collectionMint: PublicKey,
) => {
  const followerCollectionConfigPda = await findFollowerCollectionConfig(
    collectionMint,
  );
  const followerCollectionMetadata =
    await followerCollectionProgram.account.followerCollectionConfig.fetch(
      followerCollectionConfigPda.address,
    );
  return followerCollectionMetadata;
};

// For every bump (back from 255), the program has to re-compute the hash to get the PDA address for every new account
export const generateValidItem = async (
  _collectionMintAddress: PublicKey,
  follower: PublicKey,
  collectionNumberingAddress: PublicKey,
): Promise<GenerateItemRespose> => {
  let itemMint = web3.Keypair.generate();
  let itemMintAddress = itemMint.publicKey;
  let metadataPda = await findMetadataPda(itemMintAddress);
  let masterEditionPda = await findMasterEditionPda(itemMintAddress);
  let itemId = await findItemIdPda(collectionNumberingAddress, itemMintAddress);
  let followerItemTokenAccount = await findAssociatedTokenAccountPda(
    follower,
    itemMintAddress,
  );
  while (
    metadataPda.bump < 255 ||
    masterEditionPda.bump < 253 ||
    itemId.bump < 255 ||
    followerItemTokenAccount.bump < 255
  ) {
    itemMint = web3.Keypair.generate();
    itemMintAddress = itemMint.publicKey;
    metadataPda = await findMetadataPda(itemMintAddress);
    masterEditionPda = await findMasterEditionPda(itemMintAddress);
    itemId = await findItemIdPda(collectionNumberingAddress, itemMintAddress);
    followerItemTokenAccount = await findAssociatedTokenAccountPda(
      follower,
      itemMintAddress,
    );
  }

  return {
    itemMint,
    metadataPda,
    masterEditionPda,
    itemIdPda: itemId,
    followerItemTokenAccount: followerItemTokenAccount,
  };
};
