import { findInnerInstructionsForProgramAddress } from '@moonbase/solana-utils';
import { Program } from '@project-serum/anchor';
import { Connection, PublicKey, TransactionResponse } from '@solana/web3.js';
import { CollectionDistributor } from '../generated';
import { KnownPrograms } from './transaction';

export const parseAirdropTx = async (
  transaction: TransactionResponse,
  connection: Connection,
) => {
  const tokenTransferInstructions = findInnerInstructionsForProgramAddress(
    transaction,
    KnownPrograms.tokenProgram,
  );

  if (tokenTransferInstructions.length !== 1) {
    throw new Error(
      'Inner instructions are invalid for deposit IX. Multiple token transfers occuring',
    );
  }

  const tokenTransferInstruction = tokenTransferInstructions[0];
  const source = tokenTransferInstruction.accounts[0];
  const destination = tokenTransferInstruction.accounts[1];
  const accountKeys = transaction.transaction.message.accountKeys;
  const sourcePubkey = accountKeys[source];
  const destinationPubkey = accountKeys[destination];
  const parsed = await connection.getParsedAccountInfo(sourcePubkey);
  const payerPubkey = (parsed.value?.data as Record<string, any>).parsed.info
    .owner;

  if (!payerPubkey) {
    throw new Error('Unable to parse payer public key.');
  }

  // Calculate token balance change for the destination
  const preTokenBalance = transaction?.meta?.preTokenBalances?.find(
    (o) => o.accountIndex === destination,
  );
  const postTokenBalance = transaction?.meta?.postTokenBalances?.find(
    (o) => o.accountIndex === destination,
  );

  if (!preTokenBalance?.uiTokenAmount || !postTokenBalance?.uiTokenAmount) {
    throw new Error(
      'could not find the pre/post balances with the derived destination account index',
    );
  }

  const amountAirdropped =
    (postTokenBalance.uiTokenAmount.uiAmount ?? 0) -
    (preTokenBalance.uiTokenAmount.uiAmount ?? 0);

  // TODO: Parse the memo account in order to pull the airdrop message from the chain
  return {
    airdropAmount: amountAirdropped,
    source: payerPubkey,
    destination: destinationPubkey,
  };
};

export const getMemoNonce = async (
  collectionDistributionProgram: Program<CollectionDistributor>,
  depositTrackerAddress: PublicKey,
) => {
  try {
    const depositTracker =
      await collectionDistributionProgram.account.depositTracker.fetch(
        depositTrackerAddress,
      );
    return depositTracker.deposits;
  } catch (error) {
    return 0;
  }
};
