import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
} from '@solana/web3.js';
import { Context } from '../context';
import { getFollowerCollectionMetadata } from '../utils/helpers';
import { NUMBERING_NAME, RESERVE_NAME } from '../constants';
import {
  findCollectionNumberingAddress,
  findCollectionReserveAddress,
  findDepositTrackerAddress,
  findAssociatedTokenAccountAddress,
  findMemoAddress,
  getMemoNonce,
  findTokenReserveAddress,
  calculateTotalAirdropForCollection,
  filterTransactionsByType,
  parseAirdropTx,
  TransactionType,
} from '../utils';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { BN } from '@project-serum/anchor';
import { getAllTransactionsForAccount } from '@moonbase/solana-utils';

export default (context: Context) => {
  const airdrop = async ({
    collectionMint,
    message,
    airdropAmount,
    creatorSplit,
    baseMint,
  }: {
    collectionMint: PublicKey;
    message: string;
    airdropAmount: number;
    creatorSplit: number;
    baseMint: PublicKey;
  }) => {
    // Locators
    const depositorTokenAccount = await getAssociatedTokenAddress(
      baseMint,
      context.provider.wallet.publicKey,
    );
    const collectionNumberingAddress = await findCollectionNumberingAddress(
      collectionMint,
      NUMBERING_NAME,
    );
    const collectionReserveAddress = await findCollectionReserveAddress(
      collectionNumberingAddress,
      RESERVE_NAME,
    );
    const depositTrackerAddress = await findDepositTrackerAddress(
      collectionReserveAddress,
      context.provider.wallet.publicKey,
    );
    const collectionTokenAccount = await findAssociatedTokenAccountAddress(
      collectionReserveAddress,
      baseMint,
    );
    const followerCollectionConfigMetadata =
      await getFollowerCollectionMetadata(
        context.followerCollectionProgram,
        collectionMint,
      );
    const creatorAddress = followerCollectionConfigMetadata.creator;
    const creatorTokenAccount =
      followerCollectionConfigMetadata.creatorTokenAccount;
    const nonce = await getMemoNonce(
      context.collectionDistributionProgram,
      depositTrackerAddress,
    );
    const memoAddress = await findMemoAddress(
      collectionReserveAddress,
      context.provider.wallet.publicKey,
      nonce,
    );

    // Generate Ixs
    const createUserDepositTrackerIx =
      await context.collectionDistributionProgram.methods
        .createUserDepositTracker()
        .accounts({
          payer: context.provider.wallet.publicKey,
          depositor: context.provider.wallet.publicKey,
          reserve: collectionReserveAddress,
          depositTracker: depositTrackerAddress,
          systemProgram: SystemProgram.programId,
        })
        .instruction();
    const depositIx = await context.collectionDistributionProgram.methods
      .deposit(message, new BN(airdropAmount), creatorSplit)
      .accounts({
        payer: context.provider.wallet.publicKey,
        depositor: context.provider.wallet.publicKey,
        depositTracker: depositTrackerAddress,
        depositorTokenAccount,
        memo: memoAddress,
        reserve: collectionReserveAddress,
        mint: baseMint,
        reserveTokenAccount: collectionTokenAccount,
        creatorTokenAccount,
        creator: creatorAddress,
        rent: SYSVAR_RENT_PUBKEY,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      })
      .instruction();

    // Compose tx
    const tx = new Transaction();
    if (nonce === 0) {
      tx.add(createUserDepositTrackerIx);
    }
    tx.add(depositIx);
    const signature = await context.provider.send(tx);
    return { signature };
  };

  const findVolumeByMint = async (
    collectionMint: PublicKey,
    baseMint: PublicKey,
  ) => {
    const collectionNumberingAddress = await findCollectionNumberingAddress(
      collectionMint,
      NUMBERING_NAME,
    );
    const collectionReserveAddress = await findCollectionReserveAddress(
      collectionNumberingAddress,
      RESERVE_NAME,
    );
    const collectionTokenAccount = await findAssociatedTokenAccountAddress(
      collectionReserveAddress,
      baseMint,
    );
    const collectionReserveBalance =
      await context.options.connection.getTokenAccountBalance(
        collectionTokenAccount,
      );
    const collectionReserveBalanceAmount =
      (collectionReserveBalance.value.uiAmount ?? 0) *
      Math.pow(10, collectionReserveBalance.value.decimals);

    try {
      const tokenReserveAddress = await findTokenReserveAddress(
        collectionReserveAddress,
        baseMint,
      );
      const tokenReserve =
        await context.collectionDistributionProgram.account.tokenReserve.fetch(
          tokenReserveAddress,
        );
      const cumulativeTokenReserves =
        tokenReserve.cumulativeTokenReserves.toNumber();
      const prevTokenReserves = tokenReserve.prevTokenReserves.toNumber();
      const totalAirdropAmount = calculateTotalAirdropForCollection(
        collectionReserveBalanceAmount,
        prevTokenReserves,
        cumulativeTokenReserves,
      );
      return (
        totalAirdropAmount /
        Math.pow(10, collectionReserveBalance.value.decimals)
      );
    } catch (error) {
      return (
        collectionReserveBalanceAmount /
        Math.pow(10, collectionReserveBalance.value.decimals)
      );
    }
  };

  const findAllByMint = async ({
    collectionMint,
    baseMint,
  }: {
    collectionMint: PublicKey;
    baseMint: PublicKey;
  }) => {
    const collectionNumberingAddress = await findCollectionNumberingAddress(
      collectionMint,
      NUMBERING_NAME,
    );
    const collectionReserveAddress = await findCollectionReserveAddress(
      collectionNumberingAddress,
      RESERVE_NAME,
    );
    const collectionTokenAccount = await findAssociatedTokenAccountAddress(
      collectionReserveAddress,
      baseMint,
    );
    const transactionsForCollectionTokenAccouont =
      await getAllTransactionsForAccount(
        collectionTokenAccount,
        context.options.connection,
      );
    const airdropTxs = filterTransactionsByType(
      transactionsForCollectionTokenAccouont,
      TransactionType.Airdrop,
    );
    const parsedAirdrops = airdropTxs.map(async (tx) => {
      if (!tx) {
        throw new Error('Recieved null airdrop transaction.');
      }
      const parsedAirdrop = await parseAirdropTx(
        tx,
        context.options.connection,
      );

      // Sanity check to ensure that the collection token account is the destination of the airdrop
      if (
        collectionTokenAccount.toBase58() !==
        parsedAirdrop.destination.toBase58()
      ) {
        throw new Error(
          'Collection token account and transaction destination mismatch.',
        );
      }
      return {
        airdropAmount: parsedAirdrop.airdropAmount,
        source: parsedAirdrop.source,
      };
    });

    const airdrops = await Promise.all(parsedAirdrops);
    return airdrops;
  };

  return {
    airdrop,
    findVolumeByMint,
    findAllByMint,
  };
};
