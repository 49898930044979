import React from 'react';
import classnames from 'classnames';
import { TypographyTheme } from './theme';
import { FontFamily, FontWeight } from 'src/utils/constants';

export type TypographyVariant =
  | 'small-caption'
  | 'caption'
  | 'body1'
  | 'body2'
  | 'title'
  | 'bodyTitle'
  | 'h6'
  | 'h5'
  | 'h4'
  | 'h3'
  | 'h1'
  | 'h2';

export type TypographyAlign = 'left' | 'center' | 'right';

export interface TypographyProps {
  children: React.ReactNode;
  variant?: TypographyVariant;
  color?: string;
  fontFamily?: FontFamily;
  fontWeight?: FontWeight;
  align?: TypographyAlign;
  dataTestId?: string;
  underlineOnHover?: boolean;
  underline?: boolean;
  italic?: boolean;
  // Tailwind line height class
  leading?: string;
  truncate?: boolean;
  reduceOpacity?: boolean;
}

/* 
  Maps variant names to JSX text elements
  ex: <Typography variant="caption"> ===> <p>
*/
const variantComponentMapping = {
  'small-caption': 'p',
  caption: 'p',
  body1: 'p',
  body2: 'p',
  title: 'p',
  bodyTitle: 'p',
  h6: 'h6',
  h5: 'h5',
  h4: 'h4',
  h3: 'h3',
  h2: 'h2',
  h1: 'h1',
};

const Typography = ({
  children,
  color,
  variant = 'body1',
  fontFamily = 'regola',
  fontWeight = 'normal',
  align = 'left',
  dataTestId,
  underline,
  leading,
  truncate = false,
  italic = false,
  reduceOpacity = false,
  underlineOnHover = false,
}: TypographyProps) => {
  const Component = variantComponentMapping[
    variant
  ] as keyof JSX.IntrinsicElements;
  return (
    <Component
      data-testid={dataTestId}
      className={classnames(
        TypographyTheme.variant[variant],
        color ? `text-${color}` : `text-${TypographyTheme.textColor[variant]}`,
        `font-${fontFamily}`,
        `font-${fontWeight}`,
        `text-${align}`,
        leading && `leading-${leading}`,
        underline && `underline`,
        truncate && `truncate`,
        italic && 'italic',
        reduceOpacity && 'opacity-50',
        underlineOnHover && 'hover:underline',
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;
