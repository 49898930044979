import { Nft } from '@metaplex-foundation/js-next';
import { PublicKey } from '@solana/web3.js';
import { Context } from '../context';

export default (context: Context) => {
  const getMetadata = async (mint: PublicKey) => {
    const nft = await context.metaplex.nfts().findByMint(mint);
    return { name: nft.name, symbol: nft.symbol, ...nft.metadata };
  };

  const findAllByWallet = async (walletAddress: PublicKey): Promise<Nft[]> => {
    const nfts = await context.metaplex.nfts().findAllByOwner(walletAddress);
    return nfts;
  };

  const isPartOfCollection = async (nft: PublicKey, collection: PublicKey) => {
    const nftMetadata = await context.metaplex.nfts().findByMint(nft);
    return (
      nftMetadata.collection?.key.toBase58() === collection.toBase58() &&
      nftMetadata.collection.verified
    );
  };

  const getOwner = async (mint: PublicKey) => {
    const largestAccounts =
      await context.provider.connection.getTokenLargestAccounts(mint);
    if (largestAccounts.value.length < 1) {
      throw new Error('No largest accounts for this mint');
    }
    const largestAccountInfo =
      await context.provider.connection.getParsedAccountInfo(
        largestAccounts.value[0].address,
      );
    const owner = (largestAccountInfo?.value?.data as any).parsed.info?.owner;
    if (!owner) {
      throw new Error('No owner exists');
    }
    return new PublicKey(owner);
  };

  return {
    getMetadata,
    findAllByWallet,
    isPartOfCollection,
    getOwner,
  };
};
