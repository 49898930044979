import * as anchor from '@project-serum/anchor';
import * as web3 from '@solana/web3.js';
import { Keypair, PublicKey } from '@solana/web3.js';
import { BN, Program } from '@project-serum/anchor';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
} from '@solana/spl-token';
import {
  DROP_TOKENS_PROGRAM_ID,
  DROP_TOKENS_DECIMAL_MODIFIER,
} from '../constants';
import { DT_IDL } from '../generated';
import { findDropPatrolAddress } from './locators';

export const createToken = async (
  provider: anchor.Provider,
  mintPair: Keypair,
) => {
  const program = new Program(DT_IDL, DROP_TOKENS_PROGRAM_ID, provider);

  await program.methods
    .makeToken()
    .accounts({
      creator: provider.wallet.publicKey,
      mint: mintPair.publicKey,
      patrol: await findDropPatrolAddress(mintPair.publicKey),
      rent: web3.SYSVAR_RENT_PUBKEY,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: web3.SystemProgram.programId,
    })
    .signers([mintPair])
    .rpc();
  return mintPair.publicKey;
};
export const airdropDecimals = async (
  provider: anchor.Provider,
  mint: PublicKey,
  amount: number,
) => {
  const amountBn = new BN(amount * DROP_TOKENS_DECIMAL_MODIFIER);
  const program = new Program(DT_IDL, DROP_TOKENS_PROGRAM_ID, provider);
  const receiverTokenAccount = await getAssociatedTokenAddress(
    mint,
    provider.wallet.publicKey,
  );
  await program.methods
    .airdrop(amountBn)
    .accounts({
      receiver: provider.wallet.publicKey,
      receiverTokenAccount: receiverTokenAccount,
      mint: mint,
      patrol: await findDropPatrolAddress(mint),
      rent: web3.SYSVAR_RENT_PUBKEY,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: web3.SystemProgram.programId,
    })
    .rpc();
  // console.log("fake usdc drop: ", tx);
  return receiverTokenAccount;
};

export const requestPublicDevnetAirdrop = async (to: web3.PublicKey) => {
  const wallet: any = to;
  const provider = new anchor.Provider(
    getDropTokensPublicDevnetConnection(),
    wallet,
    getDropTokensConfirmOptions(),
  );
  await provider.connection.confirmTransaction(
    await provider.connection.requestAirdrop(to, 1 * web3.LAMPORTS_PER_SOL),
    'confirmed',
  );
};
export const requestPublicTestnetAirdrop = async (to: web3.PublicKey) => {
  const wallet: any = to;
  const provider = new anchor.Provider(
    new web3.Connection('https://api.testnet.solana.com', 'processed'),
    wallet,
    getDropTokensConfirmOptions(),
  );
  await provider.connection.confirmTransaction(
    await provider.connection.requestAirdrop(to, 1 * web3.LAMPORTS_PER_SOL),
    'confirmed',
  );
};

export const getDropTokensPublicDevnetConnection = () => {
  const endpoint = 'https://api.devnet.solana.com';
  const commitment: web3.Commitment = 'processed';
  return new web3.Connection(endpoint, commitment);
};

export const getDropTokensConfirmOptions = () => {
  const commitment: web3.Commitment = 'processed';
  return { preflightCommitment: commitment };
};
