import { Dialog, Transition } from '@headlessui/react';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import { CloseIcon, Icon, Typography } from 'src';

export interface ModalProps {
  isOpen: boolean;
  onClose: () => any;
  children?: React.ReactNode;
  color?: string;
  header?: {
    title?: React.ReactElement | string;
    showBorder?: boolean;
    paddingY?: string;
    paddingX?: string;
  };
  disableMobileFullScreen?: boolean;
  noBorderRadius?: boolean;
  maxWidth?: string;
  addBlur?: boolean;
  position?: 'center' | 'bottom';
}

interface HeaderProps {
  onClose: () => any;
  title?: React.ReactElement | string;
  showBorder?: boolean;
  paddingY?: string;
  paddingX?: string;
}

const Header = ({
  onClose,
  title,
  showBorder,
  paddingY = '3',
  paddingX = '4',
}: HeaderProps) => {
  return (
    <div
      data-testid="modal-header"
      className={classnames(
        `px-${paddingX} py-${paddingY} flex flex-row justify-between items-center`,
        {
          'border-b border-solid border-grayCream-200': showBorder,
        },
      )}
    >
      {typeof title === 'string' ? (
        <Typography fontWeight="bold" variant="body2">
          {title}
        </Typography>
      ) : (
        title
      )}

      <div>
        <button
          data-testid="close-btn"
          className="justify-self-start"
          onClick={onClose}
        >
          <Icon
            src={CloseIcon}
            width="5"
            height="5"
            fill="var(--color-primaryBlack-400)"
          />
        </button>
      </div>
    </div>
  );
};

const Modal = ({
  isOpen,
  onClose,
  children,
  header,
  color = 'white',
  disableMobileFullScreen,
  noBorderRadius = true,
  maxWidth = 'md',
  position = 'center',
  addBlur = true,
}: ModalProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto bg"
        onClose={onClose}
      >
        <div
          className={classnames(
            'flex justify-center min-h-screen text-center sm:block',
            {
              'items-center': position === 'center',
              'items-end': position === 'bottom',
            },
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classnames(
                'fixed inset-0 bg-background-overlay transition-opacity',
                {
                  'backdrop-filter backdrop-blur': addBlur,
                },
              )}
              data-testid="dialog-overlay"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block align-middle h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classnames(
                `inline-block p-0 overflow-hidden shadow-xl transform transition-all md:max-w-${maxWidth} md:w-full align-middle`,
              )}
              data-testid="modal-content-container"
            >
              {/* Desktop view */}
              <div
                className={classnames({
                  'hidden md:block': !disableMobileFullScreen,
                  block: disableMobileFullScreen,
                })}
              >
                <div
                  className={classnames(
                    'text-white bg-white align-middle overflow-hidden',
                    {
                      'rounded-xl': !noBorderRadius,
                    },
                  )}
                >
                  {header && <Header {...header} onClose={onClose} />}
                  <div className="max-h-80-vh overflow-y-auto">{children}</div>
                </div>
              </div>

              {/* mobile view */}
              {!disableMobileFullScreen && (
                <div
                  className={`bg-${color} min-h-screen pb-16 w-screen block md:hidden`}
                >
                  <Header {...header} onClose={onClose} />
                  <div className="px-4 py-4 overflow-y-auto">{children}</div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
