import { NUMBERING_NAME, RESERVE_NAME } from '../constants';
import { Context } from '../context';
import {
  findCollectionNumberingAddress,
  findCollectionReserveAddress,
  findItemIdAddress,
  findTokenItemClaimAccountAddress,
  findNativeItemClaimAccountAddress,
  findTokenReserveAddress,
  findAssociatedTokenAccountAddress,
  calculateClaimPercentage,
  calculateTotalAirdropForCollection,
  calculateTotalClaimable,
} from '../utils';
import { web3 } from '@project-serum/anchor';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { PublicKey, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';

export default (context: Context) => {
  const claimWithItem = async ({
    baseMint,
    itemMint,
    collectionMint,
  }: {
    baseMint: PublicKey;
    itemMint: PublicKey;
    collectionMint: PublicKey;
  }) => {
    // Locators
    const collectionNumberingAddress = await findCollectionNumberingAddress(
      collectionMint,
      NUMBERING_NAME,
    );
    const collectionReserveAddress = await findCollectionReserveAddress(
      collectionNumberingAddress,
      RESERVE_NAME,
    );
    const itemId = await findItemIdAddress(
      collectionNumberingAddress,
      itemMint,
    );
    const usdcItemClaimAccount = await findTokenItemClaimAccountAddress(
      collectionReserveAddress,
      itemId,
      baseMint,
    );
    const nativeItemClaimAccount = await findNativeItemClaimAccountAddress(
      collectionReserveAddress,
      itemId,
    );
    const tokenReserveAddress = await findTokenReserveAddress(
      collectionReserveAddress,
      baseMint,
    );
    const depositorUsdcAccount = await findAssociatedTokenAccountAddress(
      context.provider.wallet.publicKey,
      baseMint,
    );
    const collectionUsdcAccount = await findAssociatedTokenAccountAddress(
      collectionReserveAddress,
      baseMint,
    );
    const nftTokenAccount = await findAssociatedTokenAccountAddress(
      context.provider.wallet.publicKey,
      itemMint,
    );

    // only init if it does not exist
    let shouldInitializeClaimAccounts = false;
    try {
      await context.collectionDistributionProgram.account.itemClaimAccount.fetch(
        usdcItemClaimAccount,
      );
    } catch (error) {
      shouldInitializeClaimAccounts = true;
    }

    // only init if it does not exist
    let shouldInitializeTokenReserveAccount = false;
    try {
      await context.collectionDistributionProgram.account.tokenReserve.fetch(
        tokenReserveAddress,
      );
    } catch (error) {
      shouldInitializeTokenReserveAccount = true;
    }

    // Generate Ixs
    const createTokenItemClaimAccountIx =
      await context.collectionDistributionProgram.methods
        .createItemClaimAccount(baseMint)
        .accounts({
          payer: context.provider.wallet.publicKey,
          reserve: collectionReserveAddress,
          itemMint: itemMint,
          itemId: itemId,
          itemClaimAccount: usdcItemClaimAccount,
          systemProgram: web3.SystemProgram.programId,
        })
        .instruction();
    const createNativeItemClaimAccountIx =
      await context.collectionDistributionProgram.methods
        .createItemClaimAccount(null)
        .accounts({
          payer: context.provider.wallet.publicKey,
          reserve: collectionReserveAddress,
          itemMint: itemMint,
          itemId: itemId,
          itemClaimAccount: nativeItemClaimAccount,
          systemProgram: web3.SystemProgram.programId,
        })
        .instruction();
    const createTokenReserveIx =
      await context.collectionDistributionProgram.methods
        .createTokenReserve()
        .accounts({
          payer: context.provider.wallet.publicKey,
          reserve: collectionReserveAddress,
          tokenReserve: tokenReserveAddress,
          mint: baseMint,
          systemProgram: web3.SystemProgram.programId,
        })
        .instruction();
    const claimIx = await context.collectionDistributionProgram.methods
      .claim()
      .accounts({
        claimer: context.provider.wallet.publicKey,
        claimerTokenAccount: depositorUsdcAccount,
        collectionNumbering: collectionNumberingAddress,
        reserve: collectionReserveAddress,
        tokenReserve: tokenReserveAddress,
        mint: baseMint,
        reserveTokenAccount: collectionUsdcAccount,
        nativeItemClaimAccount: nativeItemClaimAccount,
        tokenItemClaimAccount: usdcItemClaimAccount,
        itemId,
        itemTokenAccount: nftTokenAccount,
        rent: SYSVAR_RENT_PUBKEY,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: web3.SystemProgram.programId,
      })
      .instruction();

    // Compose tx
    const tx = new web3.Transaction();
    if (shouldInitializeClaimAccounts) {
      tx.add(createTokenItemClaimAccountIx);
      tx.add(createNativeItemClaimAccountIx);
    }
    if (shouldInitializeTokenReserveAccount) {
      tx.add(createTokenReserveIx);
    }
    tx.add(claimIx);
    const signature = await context.provider.send(tx);
    return { signature };
  };

  const findItemClaimData = async (
    itemMint: PublicKey,
    collectionMint: PublicKey,
    baseMint: PublicKey,
  ) => {
    // Addresses
    const collectionNumberingAddress = await findCollectionNumberingAddress(
      collectionMint,
      NUMBERING_NAME,
    );
    const collectionReserveAddress = await findCollectionReserveAddress(
      collectionNumberingAddress,
      RESERVE_NAME,
    );
    const collectionTokenAccount = await findAssociatedTokenAccountAddress(
      collectionReserveAddress,
      baseMint,
    );
    const tokenReserveAddress = await findTokenReserveAddress(
      collectionReserveAddress,
      baseMint,
    );
    const itemIdAddress = await findItemIdAddress(
      collectionNumberingAddress,
      itemMint,
    );
    const itemClaimAccountAddress = await findTokenItemClaimAccountAddress(
      collectionReserveAddress,
      itemIdAddress,
      baseMint,
    );

    // Deserialized accounts
    const itemId =
      await context.collectionDistributionProgram.account.itemId.fetch(
        itemIdAddress,
      );
    const collectionNumbering =
      await context.collectionDistributionProgram.account.collectionNumbering.fetch(
        collectionNumberingAddress,
      );

    let tokenReserve, itemClaimAccount;
    try {
      tokenReserve =
        await context.collectionDistributionProgram.account.tokenReserve.fetch(
          tokenReserveAddress,
        );
      itemClaimAccount =
        await context.collectionDistributionProgram.account.itemClaimAccount.fetch(
          itemClaimAccountAddress,
        );
    } catch (error) {
      // tokenReserve & itemClaimAccount do not exist until first claim
    }

    // Balances
    const collectionReserveBalance =
      await context.provider.connection.getTokenAccountBalance(
        collectionTokenAccount,
      );
    const collectionReserveBalanceAmount =
      (collectionReserveBalance.value.uiAmount ?? 0) *
      Math.pow(10, collectionReserveBalance.value.decimals);

    const cumulativeTokenReserves = tokenReserve
      ? tokenReserve.cumulativeTokenReserves.toNumber()
      : 0;
    const prevTokenReserves = tokenReserve
      ? tokenReserve.prevTokenReserves.toNumber()
      : 0;
    const totalClaimed = itemClaimAccount
      ? itemClaimAccount.totalClaimed.toNumber()
      : 0;

    // Calculations
    const claimPercentage = calculateClaimPercentage(
      itemId.id.toNumber(),
      collectionNumbering.supply.toNumber(),
    );
    let totalAirdroppedToCollection = 0;
    totalAirdroppedToCollection = calculateTotalAirdropForCollection(
      collectionReserveBalanceAmount,
      prevTokenReserves,
      cumulativeTokenReserves,
    );
    const totalClaimableByItem = calculateTotalClaimable(
      claimPercentage,
      totalAirdroppedToCollection,
      totalClaimed,
    );

    return {
      totalClaimedByItem: totalClaimed,
      totalClaimableByItem:
        totalClaimableByItem >= 0 ? totalClaimableByItem : 0,
      totalAirdroppedToCollection,
      claimPercentage,
    };
  };

  return {
    claimWithItem,
    findItemClaimData,
  };
};
