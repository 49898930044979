import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Card, CloseIcon, Typography } from 'src';
import { Icon } from '..';
import classnames from 'classnames';

export interface FullScreenModalProps {
  onClose: () => void;
  isOpen: boolean;
  children?: React.ReactNode;
  color?: string;
  header?: {
    title?: string | React.ReactElement;
    showBorder?: boolean;
  };
  maxWidth?: string;
}

interface HeaderProps {
  title?: string | React.ReactElement;
  onClose?: () => void;
}

export const Header = ({ title, onClose }: HeaderProps) => {
  return (
    <>
      <div className="flex justify-between pt-6 px-4">
        {typeof title === 'string' ? (
          <Typography
            fontWeight="bold"
            variant="title"
            color="primaryBlack-400"
          >
            {title}
          </Typography>
        ) : (
          title
        )}

        <div>
          <button
            data-testid="close-btn"
            className="justify-self-start"
            onClick={onClose}
          >
            <Icon
              src={CloseIcon}
              width="5"
              height="5"
              fill="var(--color-primaryBlack-400)"
            />
          </button>
        </div>
      </div>
    </>
  );
};

const FullScreenModal = ({
  isOpen,
  children,
  header,
  onClose,
  color = 'background-gray',
  maxWidth = 'px-485',
}: FullScreenModalProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div
        className={classnames('fixed z-10 inset-0 overflow-y-auto bg top-16')}
      >
        <div className={`bg-${color} min-h-screen`}>
          <div className="flex justify-center text-center">
            <div
              className={`inline-block pl-3 pr-3 screen-501:px-0 overflow-hidden align-middle w-screen max-w-${maxWidth}`}
            >
              <Card color={color} padding={0} borderColor="">
                {header && <Header {...header} onClose={onClose} />}
                {children}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
};

export default FullScreenModal;
