export type Catapult = {
  version: '0.1.0';
  name: 'catapult';
  instructions: [
    {
      name: 'createCollectionNumbering';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'collectionMetadata';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'collectionMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'collectionNumbering';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'addItemToCollectionNumbering';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'collectionNumbering';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'itemMetadata';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'itemNumber';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'itemNumberAttribution';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'createCatapult';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'collectionNumbering';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'catapult';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'createNextLaunch';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'catapult';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'loadTokenPayload';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'payloadProvider';
          isMut: false;
          isSigner: true;
        },
        {
          name: 'inputTokenPayload';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'payloadMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'payloadMemo';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'catapult';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'custodiedTokenPayload';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'rent';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'associatedTokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'args';
          type: {
            defined: 'LoadTokenPayloadArgs';
          };
        },
      ];
    },
    {
      name: 'loadNativePayload';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'payloadProvider';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'catapult';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'payloadMemo';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'args';
          type: {
            defined: 'LoadNativePayloadArgs';
          };
        },
      ];
    },
    {
      name: 'fire';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'numbering';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'catapult';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'slotHashes';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'instructions';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'land';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'slotHashes';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'instructions';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'claimTokenPayload';
      accounts: [
        {
          name: 'claimant';
          isMut: false;
          isSigner: true;
        },
        {
          name: 'claimantItemTokenAccount';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'itemNumber';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'claimantTokenPayload';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'collectionNumbering';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'catapult';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'custodiedTokenPayload';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'claimNativePayload';
      accounts: [
        {
          name: 'claimant';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'claimantItemTokenAccount';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'itemNumber';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'collectionNumbering';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'catapult';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: 'unstickTokenPayload';
      accounts: [
        {
          name: 'stuckItemMint';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'stuckItemNumber';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'custodiedTokenPayload';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'collectionNumbering';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'catapult';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'launch';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'payloadMemo';
          isMut: false;
          isSigner: false;
        },
        {
          name: 'receiverTokenPayload';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'tokenProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'args';
          type: {
            defined: 'UnstickTokenPayloadArgs';
          };
        },
      ];
    },
  ];
  accounts: [
    {
      name: 'catapult';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'numLaunches';
            type: 'u64';
          },
          {
            name: 'bump';
            type: 'u8';
          },
        ];
      };
    },
    {
      name: 'collectionNumbering';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'collectionMint';
            type: 'publicKey';
          },
          {
            name: 'numberedItems';
            type: 'u64';
          },
          {
            name: 'bump';
            type: 'u8';
          },
        ];
      };
    },
    {
      name: 'itemNumberAttribution';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'mintKey';
            type: 'publicKey';
          },
        ];
      };
    },
    {
      name: 'itemNumber';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'value';
            type: 'u64';
          },
          {
            name: 'bump';
            type: 'u8';
          },
        ];
      };
    },
    {
      name: 'launch';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'index';
            type: 'u64';
          },
          {
            name: 'itemsUponFiring';
            type: {
              option: 'u64';
            };
          },
          {
            name: 'landedOn';
            type: {
              option: 'u64';
            };
          },
          {
            name: 'landingSlot';
            type: 'u64';
          },
          {
            name: 'numPayloads';
            type: 'u16';
          },
          {
            name: 'numClaims';
            type: 'u16';
          },
          {
            name: 'mints';
            type: {
              vec: 'publicKey';
            };
          },
          {
            name: 'bump';
            type: 'u8';
          },
        ];
      };
    },
    {
      name: 'payloadMemo';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'catapult';
            type: 'publicKey';
          },
          {
            name: 'sourceWallet';
            type: 'publicKey';
          },
          {
            name: 'message';
            type: 'string';
          },
          {
            name: 'amount';
            type: 'u64';
          },
          {
            name: 'launchIndex';
            type: 'u64';
          },
          {
            name: 'payloadIndex';
            type: 'u16';
          },
          {
            name: 'mintIndex';
            type: {
              option: 'u8';
            };
          },
        ];
      };
    },
  ];
  types: [
    {
      name: 'LoadNativePayloadArgs';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'message';
            type: 'string';
          },
          {
            name: 'amount';
            type: 'u64';
          },
        ];
      };
    },
    {
      name: 'LoadTokenPayloadArgs';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'message';
            type: 'string';
          },
          {
            name: 'amount';
            type: 'u64';
          },
        ];
      };
    },
    {
      name: 'UnstickTokenPayloadArgs';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'payloadMemoBump';
            type: 'u8';
          },
          {
            name: 'payloadIndex';
            type: 'u16';
          },
        ];
      };
    },
  ];
  errors: [
    {
      code: 6000;
      name: 'LaunchTokenAccountIndexError';
      msg: "launch token account index passed must be less than or equal to the launch's current number of token accounts";
    },
    {
      code: 6001;
      name: 'NoNumberedItemsError';
      msg: 'a numbering must have at least 1 numbered item before a launch for it can be fired';
    },
    {
      code: 6002;
      name: 'ZeroTokenPayloadError';
      msg: 'must include at least 1 token in payload';
    },
    {
      code: 6003;
      name: 'LandBeforeFiringError';
      msg: 'launch must be fired before it can land';
    },
    {
      code: 6004;
      name: 'LandingSlotNotReachedError';
      msg: "land can only be called after the launch's landing slot";
    },
    {
      code: 6005;
      name: 'UnverifiedItemError';
      msg: "item's collection is unverified. only items with verified collections may be added to collection numberings";
    },
    {
      code: 6006;
      name: 'ItemCollectionMismatchError';
      msg: "item's collection mint does not match the collection numbering's mint";
    },
    {
      code: 6007;
      name: 'NoCollectionError';
      msg: 'item metadata does not include a collection';
    },
    {
      code: 6008;
      name: 'UnwantedCpi';
      msg: 'cannot cpi into this ixn';
    },
    {
      code: 6009;
      name: 'UnwantedPostInstructions';
      msg: 'this ixn cannot include post instructions';
    },
    {
      code: 6010;
      name: 'RepeatFireError';
      msg: 'a launch may only be fired once. this launch already has a landing slot';
    },
    {
      code: 6011;
      name: 'RepeatLaunchError';
      msg: 'a launch may only be landed once. this launch has already landed on an item.';
    },
    {
      code: 6012;
      name: 'UnwantedPreInstructions';
      msg: 'transactions with this instruction may only include pre-instructions from its own program, the system program, or the token program';
    },
  ];
};

export const IDL: Catapult = {
  version: '0.1.0',
  name: 'catapult',
  instructions: [
    {
      name: 'createCollectionNumbering',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'collectionMetadata',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'collectionMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'collectionNumbering',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'addItemToCollectionNumbering',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'collectionNumbering',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'itemMetadata',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'itemNumber',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'itemNumberAttribution',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'createCatapult',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'collectionNumbering',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'catapult',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'createNextLaunch',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'catapult',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'loadTokenPayload',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'payloadProvider',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'inputTokenPayload',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payloadMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'payloadMemo',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'catapult',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'custodiedTokenPayload',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'args',
          type: {
            defined: 'LoadTokenPayloadArgs',
          },
        },
      ],
    },
    {
      name: 'loadNativePayload',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'payloadProvider',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'catapult',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payloadMemo',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'args',
          type: {
            defined: 'LoadNativePayloadArgs',
          },
        },
      ],
    },
    {
      name: 'fire',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'numbering',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'catapult',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'slotHashes',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'instructions',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'land',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'slotHashes',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'instructions',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'claimTokenPayload',
      accounts: [
        {
          name: 'claimant',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'claimantItemTokenAccount',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'itemNumber',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'claimantTokenPayload',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'collectionNumbering',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'catapult',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'custodiedTokenPayload',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'claimNativePayload',
      accounts: [
        {
          name: 'claimant',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'claimantItemTokenAccount',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'itemNumber',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'collectionNumbering',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'catapult',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'unstickTokenPayload',
      accounts: [
        {
          name: 'stuckItemMint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'stuckItemNumber',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'custodiedTokenPayload',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'collectionNumbering',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'catapult',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'launch',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'payloadMemo',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'receiverTokenPayload',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'args',
          type: {
            defined: 'UnstickTokenPayloadArgs',
          },
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'catapult',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'numLaunches',
            type: 'u64',
          },
          {
            name: 'bump',
            type: 'u8',
          },
        ],
      },
    },
    {
      name: 'collectionNumbering',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'collectionMint',
            type: 'publicKey',
          },
          {
            name: 'numberedItems',
            type: 'u64',
          },
          {
            name: 'bump',
            type: 'u8',
          },
        ],
      },
    },
    {
      name: 'itemNumberAttribution',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'mintKey',
            type: 'publicKey',
          },
        ],
      },
    },
    {
      name: 'itemNumber',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'value',
            type: 'u64',
          },
          {
            name: 'bump',
            type: 'u8',
          },
        ],
      },
    },
    {
      name: 'launch',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'index',
            type: 'u64',
          },
          {
            name: 'itemsUponFiring',
            type: {
              option: 'u64',
            },
          },
          {
            name: 'landedOn',
            type: {
              option: 'u64',
            },
          },
          {
            name: 'landingSlot',
            type: 'u64',
          },
          {
            name: 'numPayloads',
            type: 'u16',
          },
          {
            name: 'numClaims',
            type: 'u16',
          },
          {
            name: 'mints',
            type: {
              vec: 'publicKey',
            },
          },
          {
            name: 'bump',
            type: 'u8',
          },
        ],
      },
    },
    {
      name: 'payloadMemo',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'catapult',
            type: 'publicKey',
          },
          {
            name: 'sourceWallet',
            type: 'publicKey',
          },
          {
            name: 'message',
            type: 'string',
          },
          {
            name: 'amount',
            type: 'u64',
          },
          {
            name: 'launchIndex',
            type: 'u64',
          },
          {
            name: 'payloadIndex',
            type: 'u16',
          },
          {
            name: 'mintIndex',
            type: {
              option: 'u8',
            },
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'LoadNativePayloadArgs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'message',
            type: 'string',
          },
          {
            name: 'amount',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'LoadTokenPayloadArgs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'message',
            type: 'string',
          },
          {
            name: 'amount',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'UnstickTokenPayloadArgs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'payloadMemoBump',
            type: 'u8',
          },
          {
            name: 'payloadIndex',
            type: 'u16',
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'LaunchTokenAccountIndexError',
      msg: "launch token account index passed must be less than or equal to the launch's current number of token accounts",
    },
    {
      code: 6001,
      name: 'NoNumberedItemsError',
      msg: 'a numbering must have at least 1 numbered item before a launch for it can be fired',
    },
    {
      code: 6002,
      name: 'ZeroTokenPayloadError',
      msg: 'must include at least 1 token in payload',
    },
    {
      code: 6003,
      name: 'LandBeforeFiringError',
      msg: 'launch must be fired before it can land',
    },
    {
      code: 6004,
      name: 'LandingSlotNotReachedError',
      msg: "land can only be called after the launch's landing slot",
    },
    {
      code: 6005,
      name: 'UnverifiedItemError',
      msg: "item's collection is unverified. only items with verified collections may be added to collection numberings",
    },
    {
      code: 6006,
      name: 'ItemCollectionMismatchError',
      msg: "item's collection mint does not match the collection numbering's mint",
    },
    {
      code: 6007,
      name: 'NoCollectionError',
      msg: 'item metadata does not include a collection',
    },
    {
      code: 6008,
      name: 'UnwantedCpi',
      msg: 'cannot cpi into this ixn',
    },
    {
      code: 6009,
      name: 'UnwantedPostInstructions',
      msg: 'this ixn cannot include post instructions',
    },
    {
      code: 6010,
      name: 'RepeatFireError',
      msg: 'a launch may only be fired once. this launch already has a landing slot',
    },
    {
      code: 6011,
      name: 'RepeatLaunchError',
      msg: 'a launch may only be landed once. this launch has already landed on an item.',
    },
    {
      code: 6012,
      name: 'UnwantedPreInstructions',
      msg: 'transactions with this instruction may only include pre-instructions from its own program, the system program, or the token program',
    },
  ],
};
