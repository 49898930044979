function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import ApolloLinkTimeout from "apollo-link-timeout";
import clientLogger from "client/services/client-logger";
import fetch from "cross-fetch";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { isNodeEnv } from "universal/utils/is-node-env";
import { appConfig } from "../../client/config/app-config";
import { apiConfig } from "../../next-server/config/api-config";
var getHarmonicApiEnvs = function() {
    if (isNodeEnv) {
        return {
            API: apiConfig.HARMONIC_API_GRAPHQL_ENDPOINT
        };
    }
    return {
        API: appConfig.HARMONIC_API_GRAPHQL_ENDPOINT
    };
};
var timeoutLink = new ApolloLinkTimeout(10000); // 10 second timeout
var API = getHarmonicApiEnvs().API;
var errorLink = onError(function(param1) {
    var graphQLErrors = param1.graphQLErrors, networkError = param1.networkError;
    if (graphQLErrors) graphQLErrors.forEach(function(param) {
        var message = param.message, locations = param.locations, path = param.path;
        if (isNodeEnv) {
            // in node, on prod, console is routed to api-logger/winston
            console.error("[GraphQL Server error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
        } else {
            clientLogger.error("[GraphQL Client error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path), locations);
        }
    });
    if (networkError) {
        if (isNodeEnv) {
            console.error("[Network error]: ".concat(networkError), networkError);
        } else {
            clientLogger.error("[Network error]: ".concat(networkError), networkError);
        }
    }
});
var authLink = setContext(function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_, param) {
        var headers, ref, firebaseAuth, token;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    headers = param.headers;
                    if (!isNodeEnv) {
                        _ctx.next = 5;
                        break;
                    }
                    {
                        console.info("apollo gql server request", [
                            _.operationName,
                            _
                        ]);
                    }
                    _ctx.next = 12;
                    break;
                case 5:
                    ;
                    clientLogger.info("apollo client request", {
                        operation: _
                    });
                    firebaseAuth = getAuth(getApp());
                    _ctx.next = 10;
                    return (ref = firebaseAuth.currentUser) === null || ref === void 0 ? void 0 : ref.getIdToken();
                case 10:
                    token = _ctx.sent;
                    return _ctx.abrupt("return", {
                        headers: _objectSpread({}, headers, {
                            authorization: token ? "".concat(token) : "public"
                        })
                    });
                case 12:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(_, _1) {
        return _ref.apply(this, arguments);
    };
}());
var httpLink = createHttpLink({
    uri: "".concat(API),
    fetch: fetch
});
export var client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false
    }),
    ssrMode: "object" === "undefined",
    link: from([
        authLink,
        timeoutLink,
        errorLink,
        httpLink
    ])
});
