import {
  COLLECTION_DISTRIBUTOR_PROGRAM_ID,
  DROP_TOKENS_DECIMAL_MODIFIER,
  MINT_REQUEST_PROGRAM_ID,
  NUMBERING_NAME,
  RESERVE_NAME,
  TOKEN_METADATA_PROGRAM_ID,
} from '../constants';
import { FollowerCollectionConfig, FollowerCollectionOptions } from '../types';
import {
  findCollectionPatrol,
  findMetadataAddress,
  findCollectionNumberingAddress,
  findCollectionReserveAddress,
  findAssociatedTokenAccountAddress,
  findMasterEditionAddress,
  findFollowerCollectionConfig,
  additionalComputeBudgetIx,
  findCollectionPatrolAddress,
  generateValidItem,
  createMintInstructions,
  findMintRequestAddress,
} from '../utils';
import { BN, web3 } from '@project-serum/anchor';
import {
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  AccountMeta,
  Keypair,
  PublicKey,
  TransactionInstruction,
} from '@solana/web3.js';
import { Context } from '../context';

export default (context: Context) => {
  const create = async ({
    config,
    options,
    baseMint,
  }: {
    config: FollowerCollectionConfig;
    options: FollowerCollectionOptions;
    baseMint: PublicKey;
  }) => {
    try {
      // Derive addresses
      const collectionMint = Keypair.generate();
      const collectionPatrol = await findCollectionPatrol(
        collectionMint.publicKey,
      );
      const collectionMetadataAddress = await findMetadataAddress(
        collectionMint.publicKey,
      );
      const collectionNumbering = await findCollectionNumberingAddress(
        collectionMint.publicKey,
        NUMBERING_NAME,
      );
      const collectionReserveAddress = await findCollectionReserveAddress(
        collectionNumbering,
        RESERVE_NAME,
      );
      const collectionTokenAccount = await findAssociatedTokenAccountAddress(
        collectionPatrol.address,
        collectionMint.publicKey,
      );
      const collectionMasterEdition = await findMasterEditionAddress(
        collectionMint.publicKey,
      );
      const collectionUsdcAccount = await findAssociatedTokenAccountAddress(
        collectionReserveAddress,
        baseMint,
      );
      const creatorUsdcAccount = await getAssociatedTokenAddress(
        baseMint,
        context.provider.wallet.publicKey,
      );
      const followerCollectionConfig = await findFollowerCollectionConfig(
        collectionMint.publicKey,
      );
      const creatorUsdcTokenAccountExists =
        await context.provider.connection.getAccountInfo(creatorUsdcAccount);

      const createCreatorUsdcTokenAccount =
        createAssociatedTokenAccountInstruction(
          context.provider.wallet.publicKey,
          creatorUsdcAccount,
          context.provider.wallet.publicKey,
          baseMint,
        );
      const createCommunityReserveUsdcTokenAccount =
        createAssociatedTokenAccountInstruction(
          context.provider.wallet.publicKey,
          collectionUsdcAccount,
          collectionReserveAddress,
          baseMint,
        );
      const createCollectionMetadataIx =
        await context.followerCollectionProgram.methods
          .createCollectionMetadata(config)
          .accounts({
            payer: context.provider.wallet.publicKey,
            creator: context.provider.wallet.publicKey,
            collectionPatrol: collectionPatrol.address,
            collectionMint: collectionMint.publicKey,
            followerCollectionConfig: followerCollectionConfig.address,
            collectionTokenAccount,
            collectionMetadata: collectionMetadataAddress,
            collectionMasterEdition,
            rent: web3.SYSVAR_RENT_PUBKEY,
            tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();

      const { mintPrice, maxSupply } = options;
      let decimalModifer: number;
      if (options.expectsNativePayment) {
        decimalModifer = Math.pow(10, 9);
      } else {
        decimalModifer = Math.pow(10, 6);
      }
      const initializeCollectionIx =
        await context.followerCollectionProgram.methods
          .initializeCollection({
            mintPrice: new BN(mintPrice * decimalModifer),
            communityMintShare: options.communityMintShare,
            secondaryRoyalty: options.royalty,
            communitySecondaryRoyaltyShare: options.communityRoyaltyShare,
            maxSupply: maxSupply ? new BN(maxSupply) : null,
            expectsNativePayment: options.expectsNativePayment,
            requiresMintRequest: options.requiresMintRequest,
            reserveCurveParamater: 1000,
          })
          .accounts({
            creator: context.provider.wallet.publicKey,
            collectionPatrol: collectionPatrol.address,
            collectionMint: collectionMint.publicKey,
            followerCollectionConfig: followerCollectionConfig.address,
            collectionMetadata: collectionMetadataAddress,
            collectionNumbering,
            creatorTokenAccount: creatorUsdcAccount,
            communityTokenAccount: collectionUsdcAccount,
            collectionReserve: collectionReserveAddress,
            collectionDistributorProgram: COLLECTION_DISTRIBUTOR_PROGRAM_ID,
            rent: web3.SYSVAR_RENT_PUBKEY,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();

      // Compose tx
      const tx = new web3.Transaction();
      tx.add(
        await additionalComputeBudgetIx({
          multiplier: 2,
        }),
      );
      if (!creatorUsdcTokenAccountExists) {
        tx.add(createCreatorUsdcTokenAccount);
      }
      tx.add(createCommunityReserveUsdcTokenAccount);
      tx.add(createCollectionMetadataIx);
      tx.add(initializeCollectionIx);
      const signature = await context.provider.send(tx, [collectionMint]);
      return {
        signature,
        collectionMint: collectionMint.publicKey,
      };
    } catch (error) {
      throw error;
    }
  };

  const update = async ({
    collectionMint,
    newMaxSupply,
    newOptions,
    unlimitedMaxSupply,
  }: {
    collectionMint: PublicKey;
    newMaxSupply?: number;
    newOptions?: FollowerCollectionOptions;
    unlimitedMaxSupply?: boolean;
  }) => {
    const followerCollectionConfig = await findFollowerCollectionConfig(
      collectionMint,
    );
    const collectionNumbering = await findCollectionNumberingAddress(
      collectionMint,
      NUMBERING_NAME,
    );
    const collectionPatrol = await findCollectionPatrolAddress(collectionMint);
    let newMaxSupplyIx: TransactionInstruction | undefined = undefined;
    if (
      newMaxSupply !== undefined ||
      (unlimitedMaxSupply && newMaxSupply === undefined)
    ) {
      newMaxSupplyIx = await context.followerCollectionProgram.methods
        .changeCollectionMaxSupply(
          unlimitedMaxSupply ? null : new BN(newMaxSupply ?? 0),
        )
        .accounts({
          creator: context.provider.wallet.publicKey,
          followerCollectionConfig: followerCollectionConfig.address,
          collectionMint,
          collectionPatrol,
          collectionNumbering,
          collectionDistributorProgram: COLLECTION_DISTRIBUTOR_PROGRAM_ID,
        })
        .instruction();
    }
    let newFollowerCollectionOptionsIx: TransactionInstruction | undefined =
      undefined;
    if (newOptions !== undefined) {
      newFollowerCollectionOptionsIx =
        await context.followerCollectionProgram.methods
          .changeCollectionConfig({
            newCreator: null,
            newCommunityReserve: null,
            newCreatorTokenAccount: null,
            newCommunityTokenAccount: null,
            newExpectsNativePayment: null,
            newMintPrice: new BN(
              newOptions.mintPrice * DROP_TOKENS_DECIMAL_MODIFIER,
            ),
            newCommunityMintShare: null,
            newSecondaryRoyalty: null,
            newCommunitySecondaryRoyaltyShare: null,
          }) // TODO: update this ts type to be BN, not number
          .accounts({
            creator: context.provider.wallet.publicKey,
            followerCollectionConfig: followerCollectionConfig.address,
          })
          .instruction();
    }

    const tx = new web3.Transaction();
    if (newMaxSupplyIx) {
      tx.add(newMaxSupplyIx);
    }
    if (newFollowerCollectionOptionsIx) {
      tx.add(newFollowerCollectionOptionsIx);
    }
    const signature = await context.provider.send(tx);
    return { signature };
  };

  const mint = async ({
    collectionMintAddress,
    collectionCreatorAddress,
    baseMint,
  }: {
    collectionMintAddress: PublicKey;
    collectionCreatorAddress: PublicKey;
    baseMint: PublicKey;
  }) => {
    try {
      // Locators
      const collectionPatrol = await findCollectionPatrol(
        collectionMintAddress,
      );
      const collectionMetadataAddress = await findMetadataAddress(
        collectionMintAddress,
      );
      const collectionMasterEditionAddress = await findMasterEditionAddress(
        collectionMintAddress,
      );
      const collectionNumbering = await findCollectionNumberingAddress(
        collectionMintAddress,
        NUMBERING_NAME,
      );
      const {
        itemMint,
        metadataPda,
        masterEditionPda,
        itemIdPda,
        followerItemTokenAccount,
      } = await generateValidItem(
        collectionMintAddress,
        context.provider.wallet.publicKey,
        collectionNumbering,
      );

      const itemMintAddress = itemMint.publicKey;
      const itemMetadataAddress = metadataPda.address;
      const itemMasterEditionAddress = masterEditionPda.address;
      const followerUsdcAccount = await getAssociatedTokenAddress(
        baseMint,
        context.provider.wallet.publicKey,
      );
      const followerCollectionConfig = await findFollowerCollectionConfig(
        collectionMintAddress,
      );
      const creatorUsdcAccount = await getAssociatedTokenAddress(
        baseMint,
        collectionCreatorAddress,
      );

      const collectionReserveAddress = await findCollectionReserveAddress(
        collectionNumbering,
        RESERVE_NAME,
      );
      const collectionUsdcAccount = await findAssociatedTokenAccountAddress(
        collectionReserveAddress,
        baseMint,
      );

      const createMintIxns = await createMintInstructions(
        context.provider.wallet.publicKey,
        itemMintAddress,
        collectionPatrol.address,
        context.provider.connection,
      ); //moved create mint outside of the metadata instruction

      let remainingAccounts: AccountMeta[] = [];
      const requiresMintRequest =
        await context.followerCollectionProgram.account.followerCollectionConfig
          .fetch(followerCollectionConfig.address)
          .then((resp) => {
            return resp.requiresMintRequest;
          });
      if (requiresMintRequest) {
        const mintRequest = await findMintRequestAddress(
          context.provider.wallet.publicKey,
          collectionCreatorAddress,
          collectionMintAddress,
          collectionPatrol.address,
        );
        remainingAccounts = [
          {
            isSigner: false,
            isWritable: true,
            pubkey: mintRequest,
          },
          {
            isSigner: false,
            isWritable: false,
            pubkey: MINT_REQUEST_PROGRAM_ID,
          },
        ];
      }

      // Generate Ixs
      const createFollowerMetadataIx =
        await context.followerCollectionProgram.methods
          .createFollowerMetadata()
          .accounts({
            payer: context.provider.wallet.publicKey,
            payerTokenAccount: followerUsdcAccount,
            follower: context.provider.wallet.publicKey,
            itemMint: itemMintAddress,
            followerItemTokenAccount: followerItemTokenAccount.address,
            itemMetadata: itemMetadataAddress,
            itemId: itemIdPda.address,
            collectionMint: collectionMintAddress,
            collectionNumbering,
            followerCollectionConfig: followerCollectionConfig.address,
            collectionPatrol: collectionPatrol.address,
            creatorWallet: collectionCreatorAddress,
            communityWallet: collectionReserveAddress,
            creatorTokenAccount: creatorUsdcAccount,
            communityTokenAccount: collectionUsdcAccount,
            collectionMetadata: collectionMetadataAddress,
            collectionMasterEdition: collectionMasterEditionAddress,
            rent: web3.SYSVAR_RENT_PUBKEY,
            collectionDistributorProgram: COLLECTION_DISTRIBUTOR_PROGRAM_ID,
            tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: web3.SystemProgram.programId,
          })
          .remainingAccounts(remainingAccounts)
          .instruction();

      const initializeFollowIx = await context.followerCollectionProgram.methods
        .initializeFollow()
        .accounts({
          payer: context.provider.wallet.publicKey,
          itemMint: itemMintAddress,
          itemMetadata: itemMetadataAddress,
          itemMasterEdition: itemMasterEditionAddress,
          collectionPatrol: collectionPatrol.address,
          collectionNumbering,
          itemId: itemIdPda.address,
          rent: web3.SYSVAR_RENT_PUBKEY,
          tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programId,
        })
        .instruction();

      const additionalComputeBudgetInstruction =
        await additionalComputeBudgetIx({
          multiplier: 1.7,
        });

      // Compose tx
      const tx = new web3.Transaction().add(
        additionalComputeBudgetInstruction,
        createMintIxns[0],
        createMintIxns[1],
        createFollowerMetadataIx,
        initializeFollowIx,
      );
      let signatures: string[];
      try {
        signatures = [await context.provider.send(tx, [itemMint])];
      } catch (error) {
        signatures = await context.provider.sendAll([
          {
            tx: new web3.Transaction().add(
              additionalComputeBudgetInstruction,
              createMintIxns[0],
              createMintIxns[1],
              createFollowerMetadataIx,
            ),
            signers: [itemMint],
          },
          { tx: new web3.Transaction().add(initializeFollowIx), signers: [] },
        ]);
      }
      return {
        signatures,
        itemMint: itemMint.publicKey,
      };
    } catch (error) {
      throw error;
    }
  };

  return {
    create,
    update,
    mint,
  };
};
