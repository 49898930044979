function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { validateEnvVariable } from "universal/utils/validate-env-variable";
var DATADOG_CONFIG = {
    DATADOG_CLIENT_TOKEN: validateEnvVariable("NEXT_PUBLIC_DATADOG_CLIENT_TOKEN", process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN),
    DATADOG_APP_ID: validateEnvVariable("NEXT_PUBLIC_DATADOG_APP_ID", process.env.NEXT_PUBLIC_DATADOG_APP_ID)
};
// This url does not exist. Just for setup
var PRODUCTION = _objectSpread({
    BASE_DEPLOY_URL: "https://moonbase.com",
    BASE_API_URL: "https://api.moonbase.com",
    SPLIT_API_KEY: "5n2tm96qk3o5njtb72doscq2atk7q1kifd5g",
    USE_APP_PLACEHOLDER: false,
    MOONBASE_CONFIRMATION_EMAIL_TEMPLATE_ID: "d-34c0f23238e6449787b24c729be7acb2",
    MOONBASE_WAITLIST_ID: "eccf77d5-3fa2-491c-a09f-435005e44411",
    MOONBASE_WELCOME_EMAIL_TEMPLATE_ID: "d-661906a9893c462a97be6de8e21e00fa",
    HARMONIC_API_GRAPHQL_ENDPOINT: "https://api.harmonic.ai:443/graphql",
    HARMONIC_ASSETS_URL: "https://assets.harmonic.ai",
    MOONBASE_ASSETS_URL: "https://assets.moonbase.com"
}, DATADOG_CONFIG, {
    VERCEL_ENV: validateEnvVariable("VERCEL_ENV", process.env.NEXT_PUBLIC_VERCEL_ENV),
    NEXT_PUBLIC_PERSON_DEFAULT_IMAGE: "https://assets.moonbase.com/blue_3d_spaceman.png",
    HARMONIC_API_MIDTIER_ENDPOINT: "https://api.harmonic.ai",
    NEXT_PUBLIC_FIREBASE_DATABASE_URL: "https://moonbase-f1e50-default-rtdb.firebaseio.com/",
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: "moonbase-f1e50",
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: "moonbase-f1e50.firebaseapp.com",
    NEXT_PUBLIC_FIREBASE_API_KEY: "AIzaSyBooqi_h2WbIjr86C9BYERWscVgf4S_ZsU",
    NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL: "firebase-adminsdk-w3e4m@moonbase-f1e50.iam.gserviceaccount.com"
});
var DEVELOPMENT = _objectSpread({
    BASE_DEPLOY_URL: "https://moonbase.com",
    BASE_API_URL: "https://api-dev.moonbase.com",
    SPLIT_API_KEY: "6lpmuqblee4lbttqu7lmftg4h39rieql40d9",
    USE_APP_PLACEHOLDER: false,
    MOONBASE_CONFIRMATION_EMAIL_TEMPLATE_ID: "d-34c0f23238e6449787b24c729be7acb2",
    MOONBASE_WAITLIST_ID: "eccf77d5-3fa2-491c-a09f-435005e44411",
    MOONBASE_WELCOME_EMAIL_TEMPLATE_ID: "d-661906a9893c462a97be6de8e21e00fa",
    HARMONIC_API_GRAPHQL_ENDPOINT: "https://api.staging.harmonic.ai:443/graphql",
    HARMONIC_ASSETS_URL: "https://assets.harmonic.ai",
    MOONBASE_ASSETS_URL: "https://assets-dev.moonbase.com"
}, DATADOG_CONFIG, {
    VERCEL_ENV: validateEnvVariable("NEXT_PUBLIC_VERCEL_ENV", process.env.NEXT_PUBLIC_VERCEL_ENV),
    NEXT_PUBLIC_PERSON_DEFAULT_IMAGE: "https://assets.moonbase.com/blue_3d_spaceman.png",
    HARMONIC_API_MIDTIER_ENDPOINT: "https://api.staging.harmonic.ai",
    NEXT_PUBLIC_FIREBASE_DATABASE_URL: "https://moonbase-dev-62b9f-default-rtdb.firebaseio.com/",
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: "moonbase-dev-62b9f",
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: "moonbase-dev-62b9f.firebaseapp.com",
    NEXT_PUBLIC_FIREBASE_API_KEY: "AIzaSyCFnePaEB4ismtf8YFhJHQc_THALZB08ZE",
    NEXT_PUBLIC_FIREBASE_CLIENT_EMAIL: "firebase-adminsdk-lrrvp@moonbase-dev-62b9f.iam.gserviceaccount.com"
});
// it will allow us to use next production env vars on vercel preview and staging
export var appConfig = _objectSpread({}, process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? PRODUCTION : DEVELOPMENT);
