import { datadogLogs } from "@datadog/browser-logs";
import { appConfig } from "../config/app-config";
datadogLogs.init({
    clientToken: appConfig.DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sampleRate: 100,
    beforeSend: function(log) {
        // remove email from view url
        if (log.view) {
            log.view.url = log.view.url.replace(/email=[^&]*/, "email=REDACTED");
        }
    // discard 404 network errors
    // if (log.http && log.http.status_code === 404) {
    //   return false;
    // }
    }
});
var env = "production";
if (env === "development") {
    datadogLogs.logger.setHandler("console");
}
export default datadogLogs.logger;
