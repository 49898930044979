import {
  CompiledInnerInstruction,
  CompiledInstruction,
  ConfirmedSignatureInfo,
  Connection,
  PublicKey,
  TransactionResponse,
} from '@solana/web3.js';

export const fetchAllTransactionSignaturesForAccount = async (
  connection: Connection,
  accountId: string | PublicKey,
) => {
  const allSignatures = [];

  // This returns the first 1000, so we need to loop through until we run out of signatures to get.
  const accountKey =
    typeof accountId === 'string' ? new PublicKey(accountId) : accountId;
  let signatures: ConfirmedSignatureInfo[] = [];
  do {
    const options =
      signatures.length > 0
        ? {
            before: signatures[signatures.length - 1].signature,
          }
        : undefined;
    signatures = await connection.getSignaturesForAddress(accountKey, options);
    allSignatures.push(...signatures);
  } while (signatures.length > 0);

  return allSignatures;
};

export const existsInTransactionLogs = (
  transaction: TransactionResponse,
  substring: string,
): boolean => {
  const logMessages = transaction.meta?.logMessages;
  if (!logMessages) {
    return false;
  }
  let exists = false;
  logMessages.forEach((line) => {
    if (line.includes(substring)) {
      exists = true;
    }
  });
  return exists;
};

export const getAllTransactionsForAccount = async (
  address: PublicKey,
  connection: Connection,
) => {
  const transactionSignatures = await fetchAllTransactionSignaturesForAccount(
    connection,
    address,
  );
  const getAllTransactionsPromise = transactionSignatures.map(
    ({ signature }) => {
      return connection.getTransaction(signature);
    },
  );
  return await Promise.all(getAllTransactionsPromise);
};

export const findAccountKeyIndexForAddress = (
  transaction: TransactionResponse,
  address: PublicKey,
): number | null => {
  const index = transaction.transaction.message.accountKeys.findIndex(
    (pubkey) => pubkey.toBase58() === address.toBase58(),
  );
  if (index === -1) {
    return null;
  }
  return index;
};

export const findInnerInstructionsForProgramAddress = (
  transaction: TransactionResponse,
  programAddress: PublicKey,
) => {
  const tokenProgramAccountKeyIndex = findAccountKeyIndexForAddress(
    transaction,
    programAddress,
  );
  const innerInstructions = transaction.meta?.innerInstructions ?? [];
  const result: CompiledInstruction[] = [];
  innerInstructions.forEach((instruction: CompiledInnerInstruction) => {
    instruction.instructions.forEach((ix) => {
      if (ix.programIdIndex === tokenProgramAccountKeyIndex) {
        result.push(ix);
      }
    });
  });
  return result;
};
