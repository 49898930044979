import React from 'react';
import classnames from 'classnames';

export interface CardProps {
  padding?: number;
  children: React.ReactNode;
  color?: string;
  borderColor?: string | null;
  shadow?: string;
  roundedFull?: boolean;
  noBorderRadius?: boolean;
  overflowHidden?: boolean;
}

const Card = ({
  padding = 4,
  children,
  color = 'white',
  borderColor = 'primaryGray-400',
  shadow,
  roundedFull = false,
  noBorderRadius = false,
  overflowHidden = true,
}: CardProps) => {
  return (
    <div
      className={classnames(
        `text-white bg-${color} align-middle`,
        `p-${padding}`,
        {
          [`border border-solid border-${borderColor}`]: borderColor,
          [`shadow-${shadow}`]: shadow,
          'rounded-full': roundedFull && !noBorderRadius,
          'rounded-md': !roundedFull && !noBorderRadius,
          'overflow-hidden': overflowHidden,
        },
      )}
    >
      {children}
    </div>
  );
};

export default Card;
