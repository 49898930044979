import {
  AccountInfo,
  Commitment,
  Connection,
  PublicKey,
  TransactionResponse,
} from '@solana/web3.js';
import {
  Key,
  CollectionAuthorityRecord,
  Edition,
  EditionMarker,
  MasterEditionV1,
  MasterEditionV2,
  Metadata,
  ReservationListV1,
  ReservationListV2,
  UseAuthorityRecord,
  PROGRAM_ADDRESS,
} from '@metaplex-foundation/mpl-token-metadata';

export const mapMetaplexAccountInfoKeyToClass = (key: number) => {
  switch (key) {
    case Key.CollectionAuthorityRecord:
      return CollectionAuthorityRecord;
    case Key.EditionV1:
      return Edition;
    case Key.EditionMarker:
      return EditionMarker;
    case Key.MasterEditionV1:
      return MasterEditionV1;
    case Key.MasterEditionV2:
      return MasterEditionV2;
    case Key.MetadataV1:
      return Metadata;
    case Key.ReservationListV1:
      return ReservationListV1;
    case Key.ReservationListV2:
      return ReservationListV2;
    case Key.UseAuthorityRecord:
      return UseAuthorityRecord;
  }
  return null;
};

export const mapMetaplexAccountInfoKeyToKeyAndClass = (
  accountInfo: AccountInfo<Buffer>,
) => {
  const key = accountInfo.data.slice(0, 1).readUint8();
  const MetaplexAccountClass = mapMetaplexAccountInfoKeyToClass(key);
  return {
    key,
    MetaplexAccountClass,
  };
};

export const deserializeMetaplexAccountInfo = (
  accountInfo: AccountInfo<Buffer>,
) => {
  const { key, MetaplexAccountClass } =
    mapMetaplexAccountInfoKeyToKeyAndClass(accountInfo);
  if (MetaplexAccountClass !== null) {
    return {
      key,
      MetaplexAccountClass,
      result: MetaplexAccountClass.fromAccountInfo(accountInfo),
    };
  }
  return { key, MetaplexAccountClass, result: null };
};

export const fetchAndParseMetaplexAccount = async (
  connection: Connection,
  accountId: string | PublicKey,
  commitment: Commitment,
) => {
  const accountKey =
    typeof accountId === 'string' ? new PublicKey(accountId) : accountId;
  const accountInfo = await connection.getAccountInfo(accountKey, commitment);
  if (accountInfo !== null) {
    const { result } = deserializeMetaplexAccountInfo(accountInfo);
    if (result !== null) {
      return result[0];
    }
  }
  return null;
};

/*
  Based on https://metaplex.notion.site/Get-Collection-Methods-1ff0b118e4ce4605971df60e753a8559
  and https://github.com/metaplex-foundation/get-collection/blob/39116b680301c23c91e3960578c4a37c1c8e07c3/get-collection-ts/index.ts
*/
export const extractMetadataAddressesFromTransaction = async (
  tx: TransactionResponse,
) => {
  const metadataAddresses = [];
  const programIds = tx.transaction.message
    .programIds()
    .map((p) => p.toString());
  const accountKeys = tx.transaction.message.accountKeys.map((p) =>
    p.toString(),
  );

  // Only look in transactions that call the Metaplex token metadata program
  if (programIds.includes(PROGRAM_ADDRESS)) {
    // Go through all instructions in a given transaction
    for (const ix of tx.transaction.message.instructions) {
      // Filter for setAndVerify or verify instructions in the Metaplex token metadata program
      if (
        (ix.data == 'K' || ix.data == 'S') &&
        accountKeys[ix.programIdIndex] == PROGRAM_ADDRESS
      ) {
        const metadataAddressIndex = ix.accounts[0];
        const metadata_address =
          tx.transaction.message.accountKeys[metadataAddressIndex];
        metadataAddresses.push(metadata_address);
      }
    }
  }

  return metadataAddresses;
};

// Source: https://github.com/metaplex-foundation/metaplex-program-library/blob/6140f637c8bde853de8efd1cbbf49ca14e9e349a/token-metadata/program/src/state.rs#L22-L60
export const MAX_NAME_LENGTH = 32;
export const MAX_SYMBOL_LENGTH = 10;
export const MAX_URI_LENGTH = 200;
export const MAX_CREATOR_LIMIT = 5;
export const MAX_CREATOR_LEN = 32 + 1 + 1;
export const MAX_DATA_SIZE =
  4 +
  MAX_NAME_LENGTH +
  4 +
  MAX_SYMBOL_LENGTH +
  4 +
  MAX_URI_LENGTH +
  2 +
  1 +
  4 +
  MAX_CREATOR_LIMIT * MAX_CREATOR_LEN;
export const MAX_METADATA_LEN =
  1 + // key
  32 + // update auth pubkey
  32 + // mint pubkey
  MAX_DATA_SIZE +
  1 + // primary sale
  1 + // mutable
  9 + // nonce (pretty sure this only needs to be 2)
  2 + // token standard
  34 + // collection
  18 + // uses
  118; // Padding
