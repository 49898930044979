export type MintRequest = {
  version: '0.1.0';
  name: 'mint_request';
  instructions: [
    {
      name: 'makeMintRequest';
      accounts: [
        {
          name: 'payer';
          isMut: true;
          isSigner: true;
        },
        {
          name: 'mintRequest';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'systemProgram';
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'args';
          type: {
            defined: 'MakeMintRequestArgs';
          };
        },
      ];
    },
    {
      name: 'acceptMintRequest';
      accounts: [
        {
          name: 'acceptor';
          isMut: false;
          isSigner: true;
        },
        {
          name: 'mintRequest';
          isMut: true;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'args';
          type: {
            defined: 'AcceptMintRequestArgs';
          };
        },
      ];
    },
    {
      name: 'redeemMintRequest';
      accounts: [
        {
          name: 'mintRequest';
          isMut: true;
          isSigner: false;
        },
        {
          name: 'redeemAuthority';
          isMut: false;
          isSigner: true;
        },
      ];
      args: [
        {
          name: 'args';
          type: {
            defined: 'RedeemMintRequestArgs';
          };
        },
      ];
    },
    {
      name: 'closeMintRequest';
      accounts: [
        {
          name: 'requestor';
          isMut: false;
          isSigner: true;
        },
        {
          name: 'mintRequest';
          isMut: true;
          isSigner: false;
        },
      ];
      args: [
        {
          name: 'args';
          type: {
            defined: 'CloseMintRequestArgs';
          };
        },
      ];
    },
  ];
  accounts: [
    {
      name: 'mintRequest';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'allowance';
            type: 'u16';
          },
          {
            name: 'bump';
            type: 'u8';
          },
        ];
      };
    },
  ];
  types: [
    {
      name: 'AcceptMintRequestArgs';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'requestorKey';
            type: 'publicKey';
          },
          {
            name: 'collectionMintKey';
            type: 'publicKey';
          },
          {
            name: 'redeemAuthorityKey';
            type: 'publicKey';
          },
          {
            name: 'allowance';
            type: 'u16';
          },
        ];
      };
    },
    {
      name: 'CloseMintRequestArgs';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'acceptorKey';
            type: 'publicKey';
          },
          {
            name: 'collectionMintKey';
            type: 'publicKey';
          },
          {
            name: 'redeemAuthorityKey';
            type: 'publicKey';
          },
        ];
      };
    },
    {
      name: 'MakeMintRequestArgs';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'requestorKey';
            type: 'publicKey';
          },
          {
            name: 'acceptorKey';
            type: 'publicKey';
          },
          {
            name: 'collectionMintKey';
            type: 'publicKey';
          },
          {
            name: 'redeemAuthorityKey';
            type: 'publicKey';
          },
        ];
      };
    },
    {
      name: 'RedeemMintRequestArgs';
      type: {
        kind: 'struct';
        fields: [
          {
            name: 'requestorKey';
            type: 'publicKey';
          },
          {
            name: 'acceptorKey';
            type: 'publicKey';
          },
          {
            name: 'collectionMintKey';
            type: 'publicKey';
          },
        ];
      };
    },
  ];
  errors: [
    {
      code: 6000;
      name: 'ZeroAllowanceRedemption';
      msg: 'attempting to redeem mint request with allowance of 0';
    },
  ];
};

export const IDL: MintRequest = {
  version: '0.1.0',
  name: 'mint_request',
  instructions: [
    {
      name: 'makeMintRequest',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'mintRequest',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'args',
          type: {
            defined: 'MakeMintRequestArgs',
          },
        },
      ],
    },
    {
      name: 'acceptMintRequest',
      accounts: [
        {
          name: 'acceptor',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'mintRequest',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'args',
          type: {
            defined: 'AcceptMintRequestArgs',
          },
        },
      ],
    },
    {
      name: 'redeemMintRequest',
      accounts: [
        {
          name: 'mintRequest',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'redeemAuthority',
          isMut: false,
          isSigner: true,
        },
      ],
      args: [
        {
          name: 'args',
          type: {
            defined: 'RedeemMintRequestArgs',
          },
        },
      ],
    },
    {
      name: 'closeMintRequest',
      accounts: [
        {
          name: 'requestor',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'mintRequest',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'args',
          type: {
            defined: 'CloseMintRequestArgs',
          },
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'mintRequest',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'allowance',
            type: 'u16',
          },
          {
            name: 'bump',
            type: 'u8',
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'AcceptMintRequestArgs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'requestorKey',
            type: 'publicKey',
          },
          {
            name: 'collectionMintKey',
            type: 'publicKey',
          },
          {
            name: 'redeemAuthorityKey',
            type: 'publicKey',
          },
          {
            name: 'allowance',
            type: 'u16',
          },
        ],
      },
    },
    {
      name: 'CloseMintRequestArgs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'acceptorKey',
            type: 'publicKey',
          },
          {
            name: 'collectionMintKey',
            type: 'publicKey',
          },
          {
            name: 'redeemAuthorityKey',
            type: 'publicKey',
          },
        ],
      },
    },
    {
      name: 'MakeMintRequestArgs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'requestorKey',
            type: 'publicKey',
          },
          {
            name: 'acceptorKey',
            type: 'publicKey',
          },
          {
            name: 'collectionMintKey',
            type: 'publicKey',
          },
          {
            name: 'redeemAuthorityKey',
            type: 'publicKey',
          },
        ],
      },
    },
    {
      name: 'RedeemMintRequestArgs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'requestorKey',
            type: 'publicKey',
          },
          {
            name: 'acceptorKey',
            type: 'publicKey',
          },
          {
            name: 'collectionMintKey',
            type: 'publicKey',
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'ZeroAllowanceRedemption',
      msg: 'attempting to redeem mint request with allowance of 0',
    },
  ],
};
